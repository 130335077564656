<template>
  <v-dialog v-model="dialog" :persistent="true" max-width="60%">
    <v-card>
      <v-card-title class="headline">{{ title }}</v-card-title>
      <v-card-text class="justify">
        <span v-html="message" />
        <v-data-table
          class="elevation-1 my-2"
          :headers="cabecalho"
          :items="categoriasDuplicadasPaginada"
          hide-default-footer
          disable-pagination
          :items-per-page="-1"
          :no-data-text="$t('label.tabela_sem_conteudo')">
          <template v-slot:item.removerLinha="{ item }">
            {{ item.removerLinha.nomeCategoriaAgrupada }}
          </template>
          <template v-slot:item.incluirLinha="{ item }">
            {{ item.incluirLinha.nomeCategoriaAgrupada }}
          </template>
        </v-data-table>
        <v-pagination
          v-if="pagination.totalPage >= pagination.rowsPerPage"
          v-model="pagination.page"
          :length="pages"
          :total-visible="$vuetify.breakpoint.mdAndUp ? 15 : 7"
          class="py-2"
        ></v-pagination>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn id="sim" color="primary darken-1" text @click.native="close">
          {{ $t('label.ok') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { copyObject } from '@/common/functions/helpers';

export default {
  name: 'ModalCategoriasDuplicadas',
  data() {
    return {
      title: this.$t('label.atencao'),
      message: this.$t('errors.contrato.acoes_duplicadas_categorias_nao_agrupadas'),
      objValidacao: {
        categoriasDuplicadas: [],
      },

      dialog: false,

      cabecalho: [
        {
          text: this.$tc('label.categoria_remover_linha', 1), value: 'removerLinha', sortable: false, width: '50%',
        },
        {
          text: this.$tc('label.categoria_incluir_linha', 1), value: 'incluirLinha', sortable: false, width: '50%',
        },
      ],

      pagination: {
        page: 1,
        totalPage: 0,
        rowsPerPage: 10,
      },
    };
  },
  computed: {
    pages() {
      return Math.ceil(this.pagination.totalPage / this.pagination.rowsPerPage);
    },
    categoriasDuplicadasPaginada() {
      const listaPaginada = copyObject(this.objValidacao.categoriasDuplicadas);
      const startFrom = (this.pagination.page * this.pagination.rowsPerPage) - this.pagination.rowsPerPage;
      return listaPaginada.splice(startFrom, this.pagination.rowsPerPage);
    },
  },
  methods: {
    open() {
      this.dialog = true;
    },
    close() {
      this.dialog = false;
    },
    abrirModal(objValidacao) {
      this.objValidacao = objValidacao;
      this.pagination.totalPage = objValidacao.categoriasDuplicadas.length;
      this.$forceUpdate();
      this.open();
    },
  },
};
</script>
<style lang="scss">
.justify {
  text-align: justify;
}
</style>
