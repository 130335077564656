<template>
  <v-dialog v-model="dialog" :persistent="true" max-width="70%">
    <v-card>
      <v-card-title class="headline">{{ title }}</v-card-title>
      <v-card-text class="justify">
        <span v-html="subTitulo" />
        <v-data-table
          class="elevation-1 my-2"
          :headers="cabecalho"
          :items="acoesDuplicadasPaginada"
          hide-default-footer
          disable-pagination
          :items-per-page="-1"
          :no-data-text="$t('label.tabela_sem_conteudo')">
          <template v-slot:item.nome_categoria="{ item }">
            {{ item.categoria.nomeCategoriaAgrupada }}
          </template>
          <template v-slot:item.nome_acao_tipo="{ item }">
            {{ item.acao.nomeAcaoTipo }}
          </template>
          <template v-slot:item.data_inicio="{ item }">
            {{ item.acao.dtaInicioFormatada }}
          </template>
          <template v-slot:item.data_fim="{ item }">
            {{ item.acao.dtaFimFormatada }}
          </template>
          <template v-slot:item.calculo_bonificacao="{ item }">
            <span v-if="item.acao.calculoBonificacao">{{ $t(`configuracao.CALCULO_BONIFICACAO.${item.acao.calculoBonificacao}`) }}</span>
            <span v-else>{{ $t('label.nao_aplica') }}</span>
          </template>
          <template v-slot:item.calculo_apuracao="{ item }">
            <span v-if="item.acao.calculoApuracao">{{ $t(`configuracao.CALCULO_APURACAO.${item.acao.calculoApuracao}`) }}</span>
            <span v-else>{{ $t('label.nao_aplica') }}</span>
          </template>
          <template v-if="indDuplicadoComSemFamilia" v-slot:item.nomeFamiliaAgrupada="{ item }">
            <span v-if="item.acao.nomeFamiliaAgrupada">{{ item.acao.nomeFamiliaAgrupada }}</span>
            <span v-else>{{ $t('label.nao_aplica') }}</span>
          </template>
        </v-data-table>
        <v-pagination
          v-if="pagination.totalPage >= pagination.rowsPerPage"
          v-model="pagination.page"
          :length="pages"
          :total-visible="$vuetify.breakpoint.mdAndUp ? 15 : 7"
          class="py-2"
        ></v-pagination>
        <span v-html="$t('errors.contrato.altere_configuracoes_acao_duplicada')" />
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn id="sim" color="primary darken-1" text @click.native="close">
          {{ $t('label.ok') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { copyObject } from '@/common/functions/helpers';

export default {
  name: 'ModalAcoesDuplicadas',
  data() {
    return {
      title: this.$t('label.atencao'),
      objValidacao: {
        acoesDuplicadas: [],
      },

      dialog: false,

      cabecalho: [
        {
          text: this.$tc('label.categoria', 1), value: 'nome_categoria', sortable: false, width: '20%',
        },
        {
          text: this.$tc('label.tipo_acao', 1), value: 'nome_acao_tipo', sortable: false, width: '15%',
        },
        {
          text: this.$tc('label.data_inicio_apu', 1), value: 'data_inicio', sortable: false, width: '10%',
        },
        {
          text: this.$tc('label.data_fim_apu', 1), value: 'data_fim', sortable: false, width: '10%',
        },
        {
          text: this.$tc('label.calculo_bonificacao', 1), value: 'calculo_bonificacao', sortable: false, width: '15%',
        },
        {
          text: this.$tc('label.calculo_apuracao', 1), value: 'calculo_apuracao', sortable: false, width: '15%',
        },
      ],

      pagination: {
        page: 1,
        totalPage: 0,
        rowsPerPage: 10,
      },
      subTitulo: null,
    };
  },
  computed: {
    pages() {
      return Math.ceil(this.pagination.totalPage / this.pagination.rowsPerPage);
    },
    acoesDuplicadasPaginada() {
      const listaPaginada = copyObject(this.objValidacao.acoesDuplicadas);
      const startFrom = (this.pagination.page * this.pagination.rowsPerPage) - this.pagination.rowsPerPage;
      return listaPaginada.splice(startFrom, this.pagination.rowsPerPage);
    },
    indDuplicadoComSemFamilia() {
      return this.objValidacao.indDuplicadoComSemFamilia;
    },
  },
  methods: {
    open() {
      this.dialog = true;
    },
    close() {
      this.dialog = false;
    },
    abrirModal(objValidacao) {
      this.objValidacao = objValidacao;
      this.pagination.totalPage = objValidacao.acoesDuplicadas.length;
      this.validarCabecalhoSubTitulo();
      this.$forceUpdate();
      this.open();
    },
    validarCabecalhoSubTitulo() {
      if (this.indDuplicadoComSemFamilia && !this.cabecalho.some((e) => e.value === 'nomeFamiliaAgrupada')) {
        this.cabecalho.push({
          text: this.$tc('label.familia', 1), value: 'nomeFamiliaAgrupada', sortable: false, width: '20%',
        });
      } else if (!this.indDuplicadoComSemFamilia && this.cabecalho.some((e) => e.value === 'nomeFamiliaAgrupada')) {
        this.cabecalho.pop();
      }
      this.subTitulo = this.indDuplicadoComSemFamilia ? this.$t('errors.contrato.acoes_duplicadas_com_sem_familia')
        : this.$t('errors.contrato.acoes_duplicadas_mesma_categoria');
    },
  },
};
</script>
<style lang="scss">
.justify {
  text-align: justify;
}
</style>
