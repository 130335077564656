<template>
  <v-col cols="12" sm="8"
    v-bind:class="{ 'rodape-acoes': alturaTela <= 768}" style="text-align:right"
    v-if="fluxoCarregado">
    <slot name="acoes-padrao" />
    <v-btn @click="salvarAntesDeFinalizar()"
      dark
      class="mr-3"
      color="red"
      v-show="ultimoPasso && podeFinalizar">
      {{ $tc('label.concluir', 1) }}
    </v-btn>
    <v-btn @click="salvarAntesDeSolicitar()"
      dark
      class="mr-3"
      color="red"
      v-show="ultimoPasso && podeSolicitarAprovacao"
      :disabled="!permiteSalvar || bloqueiaSolicitarAprovacao">
      {{ $t('label.solicitar_aprovacao') }}
    </v-btn>
    <v-btn @click="abrirJustificativaAprovar()"
      dark
      class="mr-3"
      color="primary"
      v-show="ultimoPasso && podeAprovar">
      {{ $t('label.aprovar') }}
    </v-btn>
    <v-btn @click="abrirJustificativaReprovar()"
      dark
      color="red"
      class="mr-3"
      v-show="ultimoPasso && podeAprovar && exibicaoReprovarPersonalizada() && isOpcaoReprovacaoHabilitada">
      {{ $t('label.reprovar') }}
    </v-btn>
    <v-btn @click="abrirJustificativaAnalise()"
      dark
      class="mr-3"
      color="tertiary lighten-1"
      v-show="ultimoPasso && podeAprovar">
      {{ $tc('label.enviar_analise', 1) }}
    </v-btn>
    <confirm
      ref="confirmDialog"
      :persistent="true"
      :agree-label="$t('label.sim')"
      :disagree-label="$t('label.nao')"/>
    <confirm
      ref="modalSemUsuarioAprovadorCondicional"
      :persistent="true"
      hide-disagree
      :agree-label="$t('label.ok')"/>
    <planejamento-contrato-form-usuario-condicional
      ref="modalUsuarioCondicional"
      :title="$tc('title.selecao_aprovador_condicional_contrato', 1)"
      :passosFornecedor="passosFornecedor"
      :passoGerente="passoGerente"
      @selecionarUsuarioAprovador="selecionaAprovadorPassoCondicional"/>
    <planejamento-acao-justificativa
      ref="modalJustificativa"
      :is-contrato="true"
      :id-contrato="idContrato"
      :is-carta-parceria="isTipoContratoCartaParceria"
      :obrigatorio="justificativaObrigatoria"
      :salvarJustificativa="actionJustificativa"
      :is-aprovacao="isAprovacao"
      :data-inicio-contrato="dtaVigenciaInicio"
      :data-fim-contrato="dtaVigenciaFim"
      :ind-substituicao-contrato="indSubstituicaoContrato"/>

    <confirm
      ref="confirmDialogAviso"
      :persistent="true"
      hide-disagree
      :agree-label="$t('label.ok')"/>
  </v-col>
</template>
<script>
import PlanejamentoAcaoJustificativa from '@/spa/planejamento-acao/PlanejamentoAcaoJustificativa';
import { mapGetters } from 'vuex';
import { forceLoading, forceHideLoading } from '@/common/functions/loading';
import PlanejamentoContratoFormUsuarioCondicional from '@/spa/planejamento-contrato/form/PlanejamentoContratoFormUsuarioCondicional';
import Confirm from '@/shared-components/dialog/Confirm';
import BotoesFluxoMixin from '@/spa/planejamento-contrato/form/BotoesFluxoMixin';

export default {
  mixins: [BotoesFluxoMixin],
  props: {
    ultimoPasso: Boolean,
    permiteSalvar: Boolean,
    edicao: Boolean,
    contratoEdicao: Object,
    configuracao: Object,
    contratoSalvar: {
      type: Function,
      default: () => ({}),
    },
    contratoFormatar: {
      type: Function,
      default: () => ({}),
    },
    contratoValidar: {
      type: Function,
      default: () => Promise.resolve(true),
    },
    uploadEvidenciaObrigatorio: {
      type: Boolean,
      default: false,
    },
    indTemEvidencia: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      workflowContratoResource: this.$api.workflowContrato(this.$resource),
      planejamentoContratoResource: this.$api.planejamentoContratoCadastro(this.$resource),
      solicitanteFluxo: false,
      passosFornecedor: [],
      passoGerente: {},
      aprovadorFluxo: false,
      justificativaObrigatoria: false,
      isAprovacao: false,
      actionJustificativa() {},
      msgFluxoSemAprovador: '',
      possuiFluxoAprovacao: false,
      alturaTela: window.outerHeight,
      fluxoCarregado: false,
      isOpcaoReprovacaoHabilitada: false,
      indSubstituicaoContrato: false,
      bloqueiaSolicitarAprovacao: false,
    };
  },
  components: {
    PlanejamentoContratoFormUsuarioCondicional,
    PlanejamentoAcaoJustificativa,
    Confirm,
  },
  computed: {
    ...mapGetters('usuario', [
      'usuarioLogado',
    ]),
    isTipoContratoCartaParceria() {
      return this.contratoEdicao && this.contratoEdicao.passoGeral
        && this.contratoEdicao.passoGeral.isCartaParceria;
    },
    idContrato() {
      const { idContrato } = this.$route.params;
      if (!idContrato) return null;
      return Number(idContrato);
    },
    dtaVigenciaInicio() {
      if (this.contratoEdicao && this.contratoEdicao.passoGeral) {
        return this.contratoEdicao.passoGeral.dtaVigenciaInicio;
      }
      return null;
    },
    dtaVigenciaFim() {
      if (this.contratoEdicao && this.contratoEdicao.passoGeral) {
        return this.contratoEdicao.passoGeral.dtaVigenciaFim;
      }
      return null;
    },
    isUsuarioControleComercial() {
      return this.usuarioLogado.perfil.toLowerCase() === 'controle comercial';
    },
  },
  methods: {
    carregaInformacoesFluxo(idContrato) {
      if (this.contratoEdicao.passoGeral.status === 'EM_CADASTRO') {
        this.verificarUsuarioSolicitante(idContrato);
      } else {
        this.verificarUsuarioAprovador(idContrato);
        if (this.possuiFluxoAprovacao) {
          this.buscarStatusFluxo(idContrato);
        }
      }
    },
    verificaSeOpcaoReprovacaoContratoHabilitada() {
      this.planejamentoContratoResource.verificaSeOpcaoReprovacaoContratoHabilitada()
        .then((res) => {
          this.isOpcaoReprovacaoHabilitada = res.data;
        });
    },
    verificarUsuarioSolicitante(idContrato) {
      if (this.contratoEdicao.passoGeral && this.contratoEdicao.passoGeral.status === 'EM_CADASTRO') {
        this.workflowContratoResource.solicitante({ idContrato })
          .then((res) => {
            this.solicitanteFluxo = res.data.usuarioHabilitado;
            this.emitirPodeSolicitarContrato();
          });
      }
    },
    salvarAntesDeSolicitar() {
      this.bloqueiaSolicitarAprovacao = true;
      forceLoading();
      return this.contratoSalvar().then((idContrato) => {
        if (idContrato === null) {
          this.bloqueiaSolicitarAprovacao = false;
          forceHideLoading();
          return;
        }
        this.contratoValidar(true).then((isValido) => {
          if (isValido) {
            return this.solicitarAprovacao();
          }
          this.bloqueiaSolicitarAprovacao = false;
          forceHideLoading();
          return Promise.resolve(false);
        });
      });
    },
    solicitarAprovacao() {
      if (this.uploadEvidenciaObrigatorio && !this.indTemEvidencia) {
        forceHideLoading();
        this.bloqueiaSolicitarAprovacao = false;
        this.openModalAviso();
        return;
      }
      const { idContrato } = this.$route.params;
      const promises = [];
      promises.push(this.workflowContratoResource.buscaPassosFornecedor({ idContrato }));
      promises.push(this.workflowContratoResource.buscaPassosGerente({ idContrato }));
      Promise.all(promises)
        .then((response) => {
          const passosFornecedor = response[0].data;
          const passosGerente = response[1].data;
          const passosFornecedorComUsuarioAprovador = passosFornecedor.filter((passo) => passo.usuarios.length);
          forceHideLoading();
          if (passosFornecedor.length === 0 && passosGerente.length === 0) {
            forceLoading();
            this.iniciaFluxo();
          } else if (passosGerente.length > 1) {
            const msg = this.$t('errors.acordo.multiplos_passos_gerente');
            this.bloqueiaSolicitarAprovacao = false;
            this.$toast(msg);
          } else if ((passosFornecedor.length && passosFornecedor.length !== passosFornecedorComUsuarioAprovador.length)
            || (passosGerente.length && !passosGerente[0].usuarios.length)) {
            let condicionalSemUsuarioAprovador = '';
            if (passosGerente.length && !passosGerente[0].usuarios.length) {
              condicionalSemUsuarioAprovador = condicionalSemUsuarioAprovador.concat('<ul><li>Gerente Comercial</li></ul>');
            }
            if (passosFornecedor.length && passosFornecedor.length !== passosFornecedorComUsuarioAprovador.length) {
              if (passosFornecedor.some((passo) => passo.indRepresentacaoConjuntoInativado)) {
                condicionalSemUsuarioAprovador = condicionalSemUsuarioAprovador.concat('<ul><li>Fornecedor - Representação em conjunto</li></ul>');
              } else {
                condicionalSemUsuarioAprovador = condicionalSemUsuarioAprovador.concat('<ul><li>Fornecedor</li></ul>');
              }
            }
            this.msgFluxoSemAprovador = this.$t('message.nao_tem_usuario_condicional_aprovador', { condicional_nao_encontrada: condicionalSemUsuarioAprovador });
            this.$refs.modalSemUsuarioAprovadorCondicional
              .openWithParams(
                this.$t('title.atencao'),
                this.msgFluxoSemAprovador,
                null,
              );
            this.bloqueiaSolicitarAprovacao = false;
          } else {
            // Implementado suporte apenas para múltiplos passos fornecedor e/ou 1 passo gerente
            this.passosFornecedor = [...passosFornecedor];
            const [passoGerente] = passosGerente;
            this.passoGerente = passoGerente;
            this.bloqueiaSolicitarAprovacao = false;
            this.$refs.modalUsuarioCondicional.open();
          }
        }).catch(() => {
          this.bloqueiaSolicitarAprovacao = false;
        });
    },
    openModalAviso() {
      this.$refs.confirmDialogAviso
        .openWithParams(
          this.$t('title.atencao'),
          this.$t('message.upload_contrato_obrigatorio'),
        );
    },
    salvarAntesDeFinalizar() {
      if (this.uploadEvidenciaObrigatorio && !this.indTemEvidencia) {
        forceHideLoading();
        this.bloqueiaSolicitarAprovacao = false;
        this.openModalAviso();
        return;
      }
      forceLoading();
      this.contratoSalvar().then((idContrato) => {
        if (idContrato === null) {
          forceHideLoading();
          return;
        }
        this.contratoValidar(true).then((isValido) => {
          if (isValido) {
            forceHideLoading();
            this.finalizar();
          }
        });
      });
    },
    finalizar() {
      this.$refs.confirmDialog
        .openWithParams(
          this.$t('title.atencao'),
          this.$t('message.deseja_finalizar_contrato'),
          (ok) => {
            if (ok) {
              const contrato = this.contratoFormatar();
              this.planejamentoContratoResource.finalizar(contrato)
                .then(() => {
                  if (this.$route.params.from && this.$route.params.modalDashboard && this.$route.params.from === 'dashboard-inicial') {
                    this.$router.push({ name: this.$route.params.from, params: { modalDashboard: this.$route.params.modalDashboard } });
                  } else {
                    this.$router.push({
                      name: this.$route.params.from || 'planejamento-contrato',
                    });
                  }
                  this.$toast(this.$t('message.contrato_finalizado'));
                })
                .catch((err) => {
                  this.$error(this, err);
                });
            }
          },
        );
    },
    iniciaFluxo() {
      const { idContrato } = this.$route.params;
      this.workflowContratoResource.iniciaFluxo({ idContrato }, { idContrato })
        .then(() => {
          if (this.$route.params.from && this.$route.params.modalDashboard && this.$route.params.from === 'dashboard-inicial') {
            this.$router.push({ name: this.$route.params.from, params: { modalDashboard: this.$route.params.modalDashboard } });
          } else {
            this.$router.push({
              name: this.$route.params.from || 'planejamento-contrato',
            });
          }
          forceHideLoading();
          this.$toast(this.$t('message.solicitacao_aprovacao'));
        })
        .catch((err) => {
          forceHideLoading();
          this.$error(this, err);
        });
    },
    verificaSePossuiFluxoAprovacao(id) {
      const params = {
        idContrato: id,
        fluxo: 'PLANEJAMENTO',
      };
      this.workflowContratoResource.buscarFluxoTrade(params)
        .then((res) => {
          const fluxoPlanejamento = res.data;
          this.possuiFluxoAprovacao = fluxoPlanejamento.indPossuiFluxoAprovacao;
          this.fluxoCarregado = true;
          this.emitirPodeSolicitarContrato();
        });
    },
    emitirPodeSolicitarContrato() {
      setTimeout(() => {
        this.$emit('PlanejamentoContratoFormFluxoAprovacao__podeSolicitarAprovacaoContrato',
          this.podeSolicitarAprovacao);
      }, 3E2);
    },
    selecionaAprovadorPassoCondicional(dadosCondicional) {
      const { idContrato } = this.$route.params;
      const params = {
        idContrato,
        ...dadosCondicional,
      };

      forceLoading();
      this.workflowContratoResource.associaUsuariosCondicionalAprovador(params, params)
        .then(() => {
          this.iniciaFluxo();
        })
        .catch((err) => this.$error(this, err));
    },
    verificarUsuarioAprovador(idContrato) {
      if (this.contratoEdicao.passoGeral && this.contratoEdicao.passoGeral.status === 'AGUARDANDO_APROVACAO') {
        this.workflowContratoResource.aprovador({ idContrato })
          .then((response) => {
            if (response.data.usuarioHabilitado) {
              this.aprovadorFluxo = true;
            }
          });
      }
    },
    abrirJustificativaAprovar() {
      this.justificativaObrigatoria = false;
      this.isAprovacao = true;
      this.actionJustificativa = this.aprovar;
      const { idContrato } = this.$route.params;
      this.buscarInformacoesFluxo(idContrato);
    },
    buscarInformacoesFluxo(idContrato) {
      this.workflowContratoResource.status({ idContrato })
        .then((response) => {
          this.idPassoAtual = response.body.passoAtual.id;
          this.buscaIndSubstituicaoContrato(this.idPassoAtual);
        });
    },
    buscaIndSubstituicaoContrato(idPassoAtual) {
      this.workflowContratoResource.buscaFlagSubstituicaoContrato({ idPassoAtual })
        .then((response) => {
          this.indSubstituicaoContrato = response.body;
          setTimeout(() => this.$refs.modalJustificativa.open());
        });
    },
    aprovar(justificativa) {
      const { idContrato } = this.$route.params;
      this.workflowContratoResource.aprovarPasso({ idContrato },
        { observacao: justificativa })
        .then(() => {
          this.$router.push({ name: 'planejamento-contrato' });
          this.$toast(this.$t('message.contrato_aprovado'));
        })
        .catch((err) => {
          this.$error(this, err);
        });
    },
    abrirJustificativaReprovar() {
      this.justificativaObrigatoria = true;
      this.isAprovacao = false;
      this.actionJustificativa = this.reprovar;
      setTimeout(() => this.$refs.modalJustificativa.open());
    },
    reprovar(justificativa) {
      const { idContrato } = this.$route.params;
      this.workflowContratoResource.reprovar({ idContrato },
        { observacao: justificativa })
        .then(() => {
          this.$router.push({ name: 'planejamento-contrato' });
          this.$toast(this.$t('message.contrato_reprovado'));
        })
        .catch((err) => {
          this.$error(this, err);
        });
    },
    abrirJustificativaAnalise() {
      this.justificativaObrigatoria = true;
      this.isAprovacao = false;
      this.actionJustificativa = this.enviarAnalise;
      setTimeout(() => this.$refs.modalJustificativa.open());
    },
    enviarAnalise(justificativa) {
      const { idContrato } = this.$route.params;
      this.workflowContratoResource.enviarAnalise({ idContrato },
        { observacao: justificativa })
        .then(() => {
          this.$router.push({ name: 'planejamento-contrato' });
          this.$toast(this.$t('message.contrato_enviado_analise'));
        })
        .catch((err) => {
          this.$error(this, err);
        });
    },
    buscarStatusFluxo(idContrato) {
      this.workflowContratoResource.status({ idContrato })
        .then((response) => {
          this.contratoEdicao.fluxo = response.data;
          if (this.contratoEdicao.fluxo
            && this.contratoEdicao.fluxo.statusFluxo
            && (this.contratoEdicao.fluxo.statusFluxo === 'AGUARDANDO_APROVACAO'
            || this.contratoEdicao.fluxo.statusFluxo === 'EM_ANALISE'
            || this.contratoEdicao.fluxo.statusFluxo === 'REPROVADO')) {
            this.$emit('PlanejamentoContratoFormFluxoAprovacao__temFluxoContrato', true);
          }
        });
    },
  },
  mounted() {
    const { idContrato } = this.$route.params;
    if (idContrato) {
      this.verificaSePossuiFluxoAprovacao(idContrato);
    } else {
      this.fluxoCarregado = true;
    }
    this.verificaSeOpcaoReprovacaoContratoHabilitada();
  },
};
</script>
<style scoped lang="scss">
@media screen and (max-height: 800px) {
  .rodape-acoes {
    button {
      font-size: 0.88em !important;
    }
  }
}
</style>
