<template>
  <div class="mt-3 mb-2 title-float accent--text">
    <v-row v-if="statusAguardandoAprovacao">
        <v-col cols="12" sm="6" md="3" v-if="fornecedorNegociadorNome != null">
          <v-card style="height: 100%" class=" py-4">
            <v-card-title class="py-1">
              {{ `${$tc('label.tipos_fornecedor.aprovador_autorizado', 1)}`}}
            </v-card-title>
            <div class="py-1 ml-4">
              <h5 class="py-1">{{ `${$tc('title.usuario', 1)}: ` + fornecedorNegociadorNome}}</h5>
            </div>
            <div class="py-1 ml-4">
            <h5>{{ `${$tc('label.usuario_master', 1)}: ` + fornecedorNegociadorMaster}}</h5>
            </div>
          </v-card>
        </v-col>
        <v-col cols="12" sm="6" md="3" v-if="fornecedorRepresentanteNome != null">
          <v-card style="height: 100%" class=" py-4">
            <v-card-title class="py-1">
              {{ `${$tc('label.tipos_fornecedor.representante_legal', 1)}` }}
            </v-card-title>
            <div class="py-1 ml-4">
              <h5 class="py-1">{{ `${$tc('title.usuario', 1)}: ` + fornecedorRepresentanteNome}}</h5>
            </div>
            <div class="py-1 ml-4">
            <h5>{{ `${$tc('label.usuario_master', 1)}: ` + fornecedorRepresentanteMaster}}</h5>
            </div>
          </v-card>
        </v-col>
        </v-row>
    <v-row>
      <v-col class="mt-3 mb-2" cols="12">
        <span>
          {{$tc('label.acoes', 2)}}
          <v-btn
            v-if="mostrarGerarCartaParceria && !isTenantEpoca"
            class="ml-3"
            @click.native="realizarDownloadCartaParceria()">
            {{ $tc('label.gerar_carta_parceria', 1) }}
            <v-icon>receipt</v-icon>
          </v-btn>
        </span>
      </v-col>
    </v-row>
    <v-form ref="form" lazy-validation autocomplete="off">
      <v-row>
        <v-col cols="12" :sm="tamanhoCamposComuns">
          <v-card style="height: 100%">
            <v-card-title class="py-1">
              {{ `${$tc('title.base_calculo', 1)} *` }}
            </v-card-title>
            <v-row class="ma-0">
              <v-col cols="12" sm="12" class="py-0">
                <v-radio-group
                  id="plan_contrato_acao_base_calculo"
                  v-model="camposComuns.baseCalculo"
                  dense
                  hide-details
                  class="mt-0"
                  @change="camposComuns.indAcaoAlterada = true, baseCalculoAlterada()"
                  :disabled="somenteLeitura"
                  :rules="[rules.required]">
                  <v-radio
                    v-for="(b, index) in listaBasesCalculo"
                    :key="index"
                    :label="`${$t('label.' + b.label.toLowerCase())}`" :value="b.valor"></v-radio>
                </v-radio-group>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
        <v-col cols="12" :sm="tamanhoCamposComuns">
          <v-card style="height: 100%">
            <v-card-title class="py-1">
              {{ `${$tc('title.desconto', 2)} *` }}
            </v-card-title>
            <v-row class="ma-0">
              <v-col cols="12" sm="12" class="py-1">
                <v-checkbox
                  :id="`plan_contrato_acao_desconto-${d.valor}`"
                  v-for="(d, index) in listaDescontos"
                  v-model="camposComuns.descontos"
                  @change="camposComuns.indAcaoAlterada = true"
                  v-if="exibirDesconto(d)"
                  dense
                  hide-details
                  class="mt-0"
                  :rules="[rules.verificarValorSelecionado]"
                  :key="index"
                  :disabled="somenteLeitura || listaDescontos.length === 1 || d.valor === 'DEVOLUCOES'"
                  :label="`${$t('label.' + d.valor.toLowerCase())}`" :value="d.valor">
                </v-checkbox>
                <p class="caption pt-2 red--text" v-if="verificarCampoDesconto()">
                  {{$t('message.desconto_sem_valor_selecionado')}}
                </p>
                <p class="caption pt-2" v-if="verificarCampoSemDesconto()">
                  {{$t('message.sem_desconto_devolucao')}}
                </p>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
        <v-col cols="12" :sm="tamanhoCamposComuns">
          <v-card style="height: 100%">
            <v-card-title class="py-1">
              {{ `${$tc('title.tipo_pagamento', 1)} *` }}
            </v-card-title>
            <v-row class="ma-0">
              <v-col cols="12" sm="12" class="py-1">
                <v-radio-group
                  id="plan_contrato_acao_tipo_pagamento"
                  v-model="camposComuns.tipoPagamento"
                  @change="camposComuns.indAcaoAlterada = true"
                  dense
                  hide-details
                  class="mt-0"
                  :disabled="somenteLeitura"
                  :rules="[rules.required]">
                  <v-radio
                    v-for="(p, index) in listaTiposPagamento"
                    :key="index"
                    :label="`${$t(`configuracao.TIPO_PAGAMENTO.${p.cod_tipo_beneficio.toUpperCase()}`)}`"
                    :value="p.cod_tipo_beneficio"></v-radio>
                </v-radio-group>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
        <v-col cols="12" sm="6" v-if="!isTipoContratoSamsung && !isTipoContratoNivelFamilia && !isTipoContratoEpoca">
          <v-card style="height: 100%">
            <v-card-title class="py-1">
              {{ `${$tc('title.configuracao_apuracao', 1)} *` }}
              <v-spacer />
              <v-tooltip bottom max-width="620px">
                <template v-slot:activator="{ on }">
                  <v-icon
                    v-on="on"
                    color="black"
                    dark>info</v-icon>
                </template>
                <span v-html="`${$t('message.ajuda.planejamento_contrato.configuracao_apuracao')}`"></span>
              </v-tooltip>
            </v-card-title>
            <v-row class="ma-1">
              <v-col cols="12" sm="12" class="py-0">
                <v-radio-group
                  id="plan_contrato_acao_calculo_apuracao"
                  v-model="camposComuns.calculoApuracao"
                  :label="$tc('label.calculo_apuracao', 1)"
                  dense
                  hide-details
                  class="mt-0"
                  :column="false"
                  :disabled="somenteLeitura"
                  :rules="[rules.required]"
                  @click.native="camposComuns.indAcaoAlterada = true, calculoApuracaoAlterado()">
                  <v-radio
                    v-for="(ca, index) in listaCalculosApuracao"
                    class="ml-2"
                    :key="index"
                    :label="`${$t(`configuracao.CALCULO_APURACAO.${ca.valor.toUpperCase()}`)}`"
                    :value="ca.valor"></v-radio>
                </v-radio-group>
              </v-col>
            </v-row>
            <v-row class="ma-1">
              <v-col cols="12" sm="12" class="pt-0">
                <v-radio-group
                  id="plan_contrato_acao_calculo_bonificacao"
                  v-model="camposComuns.calculoBonificacao"
                  @change="camposComuns.indAcaoAlterada = true"
                  :label="$tc('label.calculo_bonificacao', 1)"
                  dense
                  hide-details
                  class="mt-0"
                  :column="false"
                  :disabled="somenteLeitura"
                  :rules="[rules.required]">
                  <v-radio
                    v-for="(cb, index) in listaCalculosBonificacao"
                    class="ml-2"
                    :key="index"
                    :label="`${$t(`configuracao.CALCULO_BONIFICACAO.${cb.valor.toUpperCase()}`)}`"
                    :value="cb.valor"></v-radio>
                </v-radio-group>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>
    </v-form>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import exportacaoCartaParceria from '@/common/functions/downloads';
import basePath from '@/common/functions/api-resource';

export default {
  components: {
  },
  props: {
    somenteLeitura: Boolean,
    novoContrato: Boolean,
    contratoEdicao: Object,
    configuracao: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapGetters('roles', [
      'getTenantId',
    ]),
    isTenantEpoca() {
      return this.getTenantId === 'epoca';
    },
    idContrato() {
      if (this.novoContrato) return null;
      const { id } = this.contratoEdicao;
      return id || null;
    },
    mostrarGerarCartaParceria() {
      if (this.novoContrato) return false;
      return ['EM_CADASTRO', 'AGUARDANDO_APROVACAO', 'APROVADO', 'EM_ANALISE'].includes(this.contratoEdicao.passoGeral.status)
        && this.contratoEdicao.passoGeral.isCartaParceria;
    },
    isTipoContratoSamsung() {
      return this.configuracao.isTipoContratoSamsung;
    },
    isTipoContratoEpoca() {
      return this.configuracao.isTipoContratoEpoca;
    },
    isTipoContratoNivelFamilia() {
      return this.configuracao.isTipoContratoNivelFamilia;
    },
    tamanhoCamposComuns() {
      if (this.isTipoContratoSamsung || this.isTipoContratoNivelFamilia || this.isTipoContratoEpoca) {
        return 4;
      }
      return 2;
    },
  },
  data() {
    return {
      planejamentoContratoResource: this.$api.planejamentoContratoCadastro(this.$resource),

      listaBasesCalculo: [],
      listaDescontos: [],
      listaTiposPagamento: [],
      listaCalculosApuracao: [],
      listaCalculosBonificacao: [],

      camposComuns: {
        baseCalculo: null,
        descontos: ['DEVOLUCOES'],
        tipoPagamento: null,
        calculoApuracao: null,
        calculoBonificacao: null,
      },
      statusAguardandoAprovacao: false,
      fornecedorNegociador: [],
      fornecedorRepresentanteLegal: [],
      fornecedorNegociadorNome: null,
      fornecedorRepresentanteNome: null,
      fornecedorNegociadorMaster: null,
      fornecedorRepresentanteMaster: null,

      rules: {
        required: (value) => !!value || this.$t('message.campo_obrigatorio'),
        verificarValorSelecionado: () => (!this.verificarCampoDesconto()) || this.$t('message.campo_obrigatorio'),
      },
    };
  },
  methods: {
    valida() {
      return this.$refs.form.validate();
    },
    getObjetoCamposComuns() {
      return this.camposComuns;
    },
    preencheListaCamposComuns() {
      const promises = [];

      promises.push(this.preencheListaCampo('base_calculo'));
      promises.push(this.preencheListaCampo('desconto'));
      promises.push(this.preencheListaCampo('tipo_beneficio'));
      promises.push(this.preencheListaCampo('calculo_apuracao'));
      promises.push(this.preencheListaCampo('calculo_bonificacao'));

      return Promise.all(promises);
    },
    preencheListaCampo(campo) {
      const params = {
        nomeCampo: campo,
        idContratoTipo: this.configuracao.configuracao.passo1.id,
      };

      if (this.idContrato != null) {
        params.idContrato = this.idContrato;
      }

      return this.planejamentoContratoResource.buscarCamposComunsAcoes(params)
        .then((res) => {
          const listaSemQuantidade = res.data.filter((c) => c.valor !== 'QUANTIDADE');

          switch (campo) {
            case 'base_calculo':
              this.listaBasesCalculo = res.data;
              if (this.listaBasesCalculo.length === 1) {
                this.camposComuns.baseCalculo = this.listaBasesCalculo[0].valor;
              }
              break;
            case 'desconto':
              this.listaDescontos = res.data;
              break;
            case 'tipo_beneficio':
              this.listaTiposPagamento = res.data;
              if (this.listaTiposPagamento.length === 1) {
                this.camposComuns.tipoPagamento = this.listaTiposPagamento[0].valor;
              }
              break;
            case 'calculo_apuracao':
              if (listaSemQuantidade.length) {
                this.listaCalculosApuracao = listaSemQuantidade;
              } else {
                this.listaCalculosApuracao = res.data;
              }
              if (this.listaCalculosApuracao.length === 1) {
                this.camposComuns.calculoApuracao = this.listaCalculosApuracao[0].valor;
              }
              break;
            case 'calculo_bonificacao':
              this.listaCalculosBonificacao = res.data;
              if (this.listaCalculosBonificacao.length === 1) {
                this.camposComuns.calculoBonificacao = this.listaCalculosBonificacao[0].valor;
              }
              break;
            default:
              break;
          }
        }).then(this.$forceUpdate())
        .catch((err) => {
          this.$error(this, err);
        });
    },
    carregarValoresDaBase() {
      if (this.contratoEdicao.passoAcoes.acoes) {
        if (this.contratoEdicao.passoAcoes.acoes.filter((a) => a.calculoApuracao !== 'QUANTIDADE').length > 0
          && (!this.isTipoContratoSamsung && !this.isTipoContratoNivelFamilia && !this.isTipoContratoEpoca)) {
          const {
            baseCalculo,
            descontos,
            tipoPagamento,
            calculoApuracao,
            calculoBonificacao,
          } = this.contratoEdicao.passoAcoes.acoes.filter((a) => a.calculoApuracao !== 'QUANTIDADE')[0];

          this.camposComuns = {
            baseCalculo,
            descontos,
            tipoPagamento,
            calculoApuracao,
            calculoBonificacao,
          };
        } else {
          const {
            baseCalculo,
            descontos,
            tipoPagamento,
            calculoBonificacao,
          } = this.contratoEdicao.passoAcoes.acoes[0];

          this.camposComuns = {
            baseCalculo,
            descontos,
            tipoPagamento,
          };

          if (!this.isTipoContratoSamsung && !this.isTipoContratoNivelFamilia && !this.isTipoContratoEpoca) {
            this.camposComuns.calculoBonificacao = calculoBonificacao;

            if (this.listaCalculosApuracao.length === 1) {
              this.camposComuns.calculoApuracao = this.listaCalculosApuracao[0].valor;
            } else {
              this.camposComuns.calculoApuracao = 'SELLIN_VALOR';
            }
          }
        }
      }
    },
    calculoApuracaoAlterado() {
      this.$emit('PlanejamentoContratoCamposComuns__valorCalculoApuracaoAlterado', this.camposComuns.calculoApuracao);
    },
    realizarDownloadCartaParceria() {
      const getBasePath = basePath('job', 'contrato/carta-parceria');
      const servico = 'gerar-carta-parceria';
      const resource = exportacaoCartaParceria(this.$http);
      const { idContrato } = this;
      resource.download(getBasePath, idContrato, servico)
        .then(() => {
          this.$toast(this.$t('message.carta_parceria_gerada_sucesso'));
        })
        .catch(() => {
          this.$toast(this.$t('message.carta_parceria_gerada_erro'));
        });
    },
    baseCalculoAlterada() {
      if (this.camposComuns.baseCalculo === 'VALOR_BRUTO') {
        if (this.isTipoContratoEpoca) {
          this.camposComuns.descontos = [];
        } else {
          this.camposComuns.descontos = ['DEVOLUCOES'];
        }
      }
      if (this.camposComuns.baseCalculo === 'VALOR_LIQUIDO') {
        this.camposComuns.descontos.push('ICMS', 'IPI', 'PIS_COFINS');
        alert(this.$t('message.alerta_base_calculo')); // eslint-disable-line
      }
    },
    exibirDesconto(desconto) {
      if (this.camposComuns.baseCalculo === 'VALOR_BRUTO' && desconto.valor !== 'DEVOLUCOES') {
        return false;
      }
      if (desconto.valor === 'DEVOLUCOES' && this.isTipoContratoEpoca) {
        return false;
      }
      return true;
    },
    verificarCampoDesconto() {
      if (!this.isTipoContratoEpoca || (this.camposComuns.descontos && this.camposComuns.descontos.length > 1)
          || this.camposComuns.baseCalculo === 'VALOR_BRUTO' || !this.camposComuns.baseCalculo) {
        return false;
      }
      return true;
    },
    verificarCampoSemDesconto() {
      if (this.isTipoContratoEpoca && this.camposComuns.baseCalculo === 'VALOR_BRUTO') {
        return true;
      }
      return false;
    },
    carregarInfoUsuario() {
      if (this.contratoEdicao.passoGeral.status === 'AGUARDANDO_APROVACAO') {
        this.statusAguardandoAprovacao = true;

        for (let i = 0; i < this.contratoEdicao.passoGeral.usuarioFornecedorAprovador.length; i += 1) {
          const { tipoFornecedor } = this.contratoEdicao.passoGeral.usuarioFornecedorAprovador[i];
          if (tipoFornecedor === 'APROVADOR_AUTORIZADO') {
            this.fornecedorNegociador = this.contratoEdicao.passoGeral.usuarioFornecedorAprovador[i];
            this.fornecedorNegociadorNome = this.fornecedorNegociador.nomUsuario;
            this.fornecedorNegociadorMaster = this.fornecedorNegociador.indUsuarioExternoMaster ? this.$t('label.sim') : this.$t('label.nao');
          }
          if (tipoFornecedor === 'REPRESENTANTE_LEGAL') {
            this.fornecedorRepresentanteLegal = this.contratoEdicao.passoGeral.usuarioFornecedorAprovador[i];
            this.fornecedorRepresentanteNome = this.fornecedorRepresentanteLegal.nomUsuario;
            this.fornecedorRepresentanteMaster = this.fornecedorRepresentanteLegal.indUsuarioExternoMaster ? this.$t('label.sim') : this.$t('label.nao');
          }
        }
      }
    },
    getListaCamposComuns(campo) {
      switch (campo) {
        case 'base_calculo':
          return this.listaBasesCalculo;
        case 'desconto':
          return this.listaDescontos;
        case 'tipo_beneficio':
          return this.listaTiposPagamento;
        case 'calculo_apuracao':
          return this.listaCalculosApuracao;
        case 'calculo_bonificacao':
          return this.listaCalculosBonificacao;
        default:
          return [];
      }
    },
  },
  mounted() {
    this.preencheListaCamposComuns().then(() => {
      if (!this.novoContrato) {
        this.carregarValoresDaBase();
        this.carregarInfoUsuario();
      }
    });
  },
};
</script>
<style>
#plan_contrato_acao_calculo_apuracao legend, #plan_contrato_acao_calculo_bonificacao legend {
  max-width: 30%;
}
</style>
