<template>
  <v-card class="PlanejamentoContratoFormCampos__Card--treslinhas">
    <v-form ref="formOrigem"
      lazy-validation
      auto-complete="off">
      <v-container fluid grid-list-md>
        <v-row>
          <v-col cols="12" >
            <v-select
              id="planejamento-origem-contrato"
              v-model="origemContrato"
              :items="origemContratoConfiguracao"
              :disabled="somenteLeitura || unicaOrigemContrato"
              :label="$t('label.origem_contrato')"
              item-text="texto"
              item-value="valor"
              @input="limparOrigem">
            </v-select>
          </v-col>
          <v-col cols="12" >
            <v-autocomplete
              id="planejamento-autocomplete-grupo-fornecedor"
              class="custom-autocomplete"
              v-if="exibeOrigemGrupoFornecedor"
              v-model="contrato.grupoFornecedor"
              return-object
              :rules="rules.requiredFunction('grupoFornecedor', 'nomExtensao')"
              :items="gruposFornecedores"
              :label="`${$tc('label.grupo_fornecedor', 1)} *`"
              :no-data-text="$tc('message.nenhum_registro', 1)"
              :item-text="isAmbienteEpoca ? 'idExternoNome' : 'codNome'"
              item-value="id"
              required
              :disabled="somenteLeitura"
              :clearable="true"
              @input="grupoFornecedorAlterado"
              :placeholder="somenteLeitura ? null : $tc('message.digite_para_pesquisar', 1)"
              @click:append="() => triggerSelecao('planejamento-autocomplete-grupo-fornecedor')"
              @click.native="(i) => buscaAutocomplete(i, buscaGruposFornecedores)"
              :search-input.sync="triggerGrupoFornecedor">
            </v-autocomplete>
            <v-autocomplete
              id="planejamento-autocomplete-fornecedor"
              class="custom-autocomplete"
              v-else
              v-model="contrato.fornecedor"
              return-object
              :filter="filtrarFornecedores"
              :rules="rules.requiredFunction('fornecedor', 'codNomeCnpj')"
              :items="fornecedores"
              :label="`${$tc('label.fornecedor', 1)} *`"
              :no-data-text="$tc('message.nenhum_registro', 1)"
              item-text="codNomeCnpj"
              item-value="id"
              required
              :disabled="somenteLeitura"
              :clearable="true"
              @input="fornecedorAlterado"
              :placeholder="somenteLeitura ? null : $tc('message.digite_para_pesquisar', 1)"
              @click:append="() => triggerSelecao('planejamento-autocomplete-fornecedor')"
              @click.native="(i) => buscaAutocomplete(i, buscaFornecedores)"
              :search-input.sync="triggerFornecedor">
            </v-autocomplete>
          </v-col>
          <v-col cols="12"
            v-if="exibeFornecedorPagador">
            <v-autocomplete
              id="planejamento-autocomplete-fornecedorpagador"
              class="custom-autocomplete"
              v-model="contrato.fornecedorPagador"
              return-object
              :rules="rules.requiredFunctionOpcional('fornecedorPagador', 'codNomeCnpj', fornecedorPagadorObrigatorio)"
              :items="fornecedoresPagadores"
              :label="labelFornecedorPagador"
              :no-data-text="$tc('message.nenhum_registro', 1)"
              item-text="codNomeCnpj"
              item-value="id"
              :required="fornecedorPagadorObrigatorio"
              :disabled="somenteLeitura || desabilitaFornecedorPagador"
              :placeholder="(somenteLeitura || desabilitaFornecedorPagador) ? null : $tc('message.digite_para_pesquisar', 1)"
              :clearable="true">
            </v-autocomplete>
          </v-col>
        </v-row>
      </v-container>
    </v-form>
  </v-card>
</template>
<script>
import { mapGetters } from 'vuex';
import { buscaAutocomplete, triggerSelecao } from '@/common/functions/autocomplete-utils';
import ContratoConfiguracao from '@/spa/planejamento-contrato/ContratoConfiguracao';

export default {
  props: {
    somenteLeitura: Boolean,
    contrato: Object,
    configuracao: {
      type: Object,
      required: true,
    },
  },
  watch: {
    triggerGrupoFornecedor(val) {
      const { grupoFornecedor } = this.contrato;
      if (grupoFornecedor && grupoFornecedor.nomExtensao === val) {
        return;
      }
      if (this.timeoutTrigger) {
        window.clearTimeout(this.timeoutTrigger);
      }
      this.timeoutTrigger = window.setTimeout(() => {
        if (val) this.buscaGruposFornecedores(val);
      }, 500);
    },
    triggerFornecedor(val) {
      const { fornecedor } = this.contrato;
      if (fornecedor && fornecedor.codNomeCnpj === val) {
        return;
      }
      if (this.timeoutTrigger) {
        window.clearTimeout(this.timeoutTrigger);
      }
      this.timeoutTrigger = window.setTimeout(() => {
        if (val) this.buscaFornecedores(val);
      }, 500);
    },
  },
  computed: {
    ...mapGetters('implantacao', [
      'chaveEmpresa',
    ]),
    isAmbienteEpoca() {
      return this.chaveEmpresa === 'EPOCA';
    },
    isTipoContratoEpoca() {
      return this.configuracao.isTipoContratoEpoca;
    },
    desabilitaFornecedorPagador() {
      if (this.fornecedoresPagadores.length === 1) {
        return true;
      }
      const { grupoFornecedor } = this.contrato;

      if (this.isTipoContratoEpoca) {
        return false;
      }

      if (this.origemContrato === 'FORNECEDOR' || !grupoFornecedor || !grupoFornecedor.id) {
        return true;
      }
      return false;
    },
    origemContratoConfiguracao() {
      const { origemContrato } = this.configuracao;
      return this.itensOrigemContrato
        .filter((item) => origemContrato.indexOf(item.valor) >= 0);
    },
    exibeOrigemGrupoFornecedor() {
      return this.origemContrato === 'GRUPO_FORNECEDOR';
    },
    unicaOrigemContrato() {
      return this.origemContratoConfiguracao.length === 1;
    },
    exibeFornecedorPagador() {
      return this.configuracao.habilitaFornecedorPagador;
    },
    labelFornecedorPagador() {
      if (this.configuracao.fornecedorPagadorObrigatorio) {
        return `${this.$tc('label.fornecedor_faturado', 1)} *`;
      }
      return this.$tc('label.fornecedor_faturado', 1);
    },
    fornecedorPagadorObrigatorio() {
      return this.configuracao.fornecedorPagadorObrigatorio;
    },
    prazoVencimentoContrato() {
      if (!this.contrato.fornecedorPagador) {
        return null;
      }
      return this.contrato.fornecedorPagador.prazoVencimentoContrato;
    },
    isTipoContratoSamsung() {
      const contratoModel = new ContratoConfiguracao(this.configuracao.configuracao);
      return contratoModel.isTipoContratoSamsung;
    },
  },
  data() {
    return {
      planejamentoAcaoResource: this.$api.planejamentoAcaoCadastro(this.$resource),

      triggerGrupoFornecedor: null,
      triggerFornecedor: null,
      timeoutTrigger: null,

      gruposFornecedores: [],
      fornecedores: [],
      fornecedoresPagadores: [],

      origemContrato: 'GRUPO_FORNECEDOR',
      itensOrigemContrato: [
        {
          valor: 'GRUPO_FORNECEDOR',
          texto: this.$tc('label.grupo_fornecedor', 1),
        },
        {
          valor: 'FORNECEDOR',
          texto: this.$tc('label.fornecedor', 1),
        },
      ],

      rules: {
        required: (value) => !!value || this.$t('message.campo_obrigatorio'),
        requiredFunctionOpcional: (objLabel, valueLabel, obrigatorio) => [
          () => {
            if (!obrigatorio) {
              return true;
            }

            const objeto = this.contrato[objLabel] || {};
            const val = objeto[valueLabel];
            const msg = this.$t('message.campo_obrigatorio');
            return !!(val) || msg;
          },
        ],
        requiredFunction: (objLabel, valueLabel) => [
          () => {
            const objeto = this.contrato[objLabel] || {};
            const val = objeto[valueLabel];
            const msg = this.$t('message.campo_obrigatorio');
            return !!(val) || msg;
          },
        ],
      },
    };
  },
  methods: {
    buscaAutocomplete,
    triggerSelecao,
    filtrarFornecedores(item, queryText) {
      return item.codNomeCnpj.toLowerCase().indexOf(queryText.toLowerCase()) > -1
        || item.desCnpj.replace(/[./-]/g, '').indexOf(queryText) > -1;
    },
    buscaGruposFornecedores(autocomplete) {
      if (this.isTipoContratoSamsung) {
        autocomplete = 'SAMSUNG';
      }

      const parametros = {
        autocomplete,
      };
      this.planejamentoAcaoResource.buscarGruposFornecedores(parametros)
        .then((res) => {
          this.gruposFornecedores = res.data;
        })
        .catch((err) => {
          this.$toast(err.data.error);
        });
    },
    buscaFornecedores(autocomplete) {
      const parametros = {
        autocomplete,
        pagador: false,
      };
      this.planejamentoAcaoResource.buscarFornecedores(parametros)
        .then((res) => {
          this.fornecedores = res.data;
        })
        .catch((err) => {
          this.$toast(err.data.error);
        });
    },
    buscaFornecedoresPagadores() {
      const parametros = {};

      if (this.isTipoContratoEpoca && this.origemContrato === 'FORNECEDOR') {
        parametros.idGrupoFornecedor = this.contrato.fornecedor ? this.contrato.fornecedor.idGrupoFornecedor : 0;
      } else {
        parametros.idGrupoFornecedor = this.contrato.grupoFornecedor ? this.contrato.grupoFornecedor.id : 0;
      }

      if (this.isTipoContratoEpoca) {
        parametros.pagador = false;
        parametros.apenasMatriz = true;
      } else {
        parametros.pagador = true;
      }
      return this.planejamentoAcaoResource.buscarFornecedoresPagadores(parametros)
        .then((res) => {
          this.fornecedoresPagadores = res.data;
        })
        .catch((err) => {
          this.$toast(err.data.error);
        });
    },
    limparOrigem() {
      const { fornecedor, fornecedorPagador, grupoFornecedor } = this.contrato;
      if (grupoFornecedor && grupoFornecedor.id) {
        this.contrato.grupoFornecedor = {};
      }
      if (fornecedorPagador && fornecedorPagador.id) {
        this.contrato.fornecedorPagador = {};
      }
      if (fornecedor && fornecedor.id) {
        this.contrato.fornecedor = {};
      }
    },
    grupoFornecedorAlterado(grupoFornecedor) {
      if (grupoFornecedor) {
        this.$emit('PlanejamentoContratoForm__CampoOrigemAlterado', grupoFornecedor.nomExtensao);
      }

      if (!this.exibeFornecedorPagador) {
        return;
      }

      this.buscaFornecedoresPagadores().then(() => {
        if (this.fornecedoresPagadores.length === 1) {
          const unico = this.fornecedoresPagadores[0];
          this.contrato.fornecedorPagador = unico;
        } else {
          this.contrato.fornecedorPagador = {};
        }
      });
    },
    fornecedorAlterado(fornecedor) {
      if (this.isTipoContratoEpoca && this.origemContrato === 'FORNECEDOR') {
        this.buscaFornecedoresPagadores().then(() => {
          if (this.fornecedoresPagadores.length === 1) {
            const unico = this.fornecedoresPagadores[0];
            this.contrato.fornecedorPagador = unico;
          } else {
            // Se o grupo fornecedor do fornecedor não possuir matriz então trazer ele mesmo como pagador
            const fornecedorSemMatriz = this.fornecedoresPagadores.find((f) => !f.possuiMatriz);
            if (fornecedorSemMatriz) {
              this.fornecedoresPagadores = fornecedor ? [fornecedor] : [];
              this.contrato.fornecedorPagador = fornecedor ? { ...fornecedor } : null;
            } else {
              this.contrato.fornecedorPagador = {};
            }
          }
        });
      } else {
        this.contrato.fornecedorPagador = fornecedor ? { ...fornecedor } : null;
        this.fornecedoresPagadores = [
          this.contrato.fornecedorPagador,
        ];
      }
      if (fornecedor) {
        this.$emit('PlanejamentoContratoForm__CampoOrigemAlterado', fornecedor.codNomeCnpj);
      }
    },
    trataOrigemContrato() {
      if (this.unicaOrigemContrato) {
        this.origemContrato = this.origemContratoConfiguracao[0].valor;
      }
    },
    setContratoEdicao(contrato) {
      if (this.contrato.id) {
        const { fornecedor, grupoFornecedor, fornecedorPagador } = contrato;
        if (grupoFornecedor) {
          this.origemContrato = 'GRUPO_FORNECEDOR';
          this.gruposFornecedores = [grupoFornecedor];
        } else {
          this.origemContrato = 'FORNECEDOR';
          this.fornecedores = [fornecedor];
        }
        if (fornecedorPagador) {
          this.fornecedoresPagadores = [fornecedorPagador];
        }
      }
    },
    getTextoOrigem() {
      return this.$tc(`label.${this.origemContrato.toLowerCase()}`, 1);
    },
    valida() {
      if (!this.$refs.formOrigem.validate()) {
        return false;
      }
      return true;
    },
  },
  mounted() {
    this.trataOrigemContrato();
  },
};
</script>
