<template>
  <v-card style="min-height:168px; height: 100%;">
    <v-card-title style="word-break: break-word; width: 97%;">
      {{ $tc('label.intervalo_meta', 1) }} {{ subtitulo }}
    </v-card-title>
    <v-card-text>
      <v-container fluid px-0 py-0>
        <v-row>
          <v-col cols="12" >
            <v-data-table
              :headers="headers"
              :items="metas"
              hide-default-footer
              disable-pagination
              :key="tableKey"
              :items-per-page="-1"
              :no-data-text="$t('message.nenhum_intervalo')"
              style="overflow:hidden">
              <template v-slot:item="{ item }">
                <tr>
                  <td class="text-center">
                    <span v-text="exibirMeta(item.vlrMetaDe)"></span>
                  </td>
                  <td class="text-center">
                    <span v-if="item.vlrMetaAte" v-text="exibirMeta(item.vlrMetaAte)"></span>
                  </td>
                  <td class="text-center">
                    <span v-text="exibirPagamento(item.vlrBonificacao)"></span>
                  </td>
                  <td class="justify-center px-0" style="text-align: center;" v-if="!somenteLeitura">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on }">
                        <v-btn icon class="mx-0" v-on="on" @click="editar(item)">
                          <v-icon>edit</v-icon>
                        </v-btn>
                      </template>
                      <span>{{ $t('label.editar') }}</span>
                    </v-tooltip>

                    <v-tooltip bottom>
                      <template v-slot:activator="{ on }">
                        <v-btn icon class="mx-0" v-on="on" @click="openConfirmRemocao(item)">
                          <v-icon>delete</v-icon>
                        </v-btn>
                      </template>
                      <span>{{ $tc('label.remover') }}</span>
                    </v-tooltip>
                  </td>
                </tr>
              </template>
            </v-data-table>
            <v-btn absolute
              small
              right
              top
              icon
              @click="incluirIntervaloZerado()"
              color="primary"
              v-if="!somenteLeitura">
              <v-tooltip bottom left>
                <span>{{$t('label.novo')}}</span>
                <template v-slot:activator="{ on }">
                  <v-icon v-on="on">add</v-icon>
                </template>
              </v-tooltip>
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn color="primary darken-1" text @click.native="fecharModalMetaIntervalo">{{ $t('label.ok') }}</v-btn>
    </v-card-actions>

    <v-dialog v-model="showModal" eager max-width="600">
      <v-form ref="formModal" lazy-validation>
        <v-card>
          <v-card-title>
            <span class="headline">
              {{ $tc('label.intervalo_meta', 1) }}
            </span>
          </v-card-title>
          <v-card-text>
            <v-container fluid grid-list-md>
              <v-row>
                <v-col cols="12" sm="6" md="4">
                  <input-money
                    v-if="calculoValor && !unidadeQuantidade"
                    :label="`${$tc('label.meta_de', 1)} *`"
                    v-model="meta.vlrMetaDe"
                    @change="acao.indAcaoAlterada = true"
                    :value="meta.vlrMetaDe"
                    :disabled="somenteLeitura"
                    class="v-text-field"
                    :rules="[rules.required]"
                    :required="true"/>
                  <v-text-field
                    v-else-if="calculoVolume && !unidadeQuantidade"
                    :label="`${$tc('label.meta_de', 1)} *`"
                    v-model="meta.vlrMetaDe"
                    @change="acao.indAcaoAlterada = true"
                    :disabled="somenteLeitura"
                    type="Number"
                    :min="0"
                    :suffix="sigla"
                    :rules="[rules.required]"
                  ></v-text-field>
                  <v-text-field
                    v-else-if="calculoPercentual && !unidadeQuantidade"
                    :label="`${$tc('label.meta_de', 1)} *`"
                    v-model="meta.vlrMetaDe"
                    @change="acao.indAcaoAlterada = true"
                    :disabled="somenteLeitura"
                    type="Number"
                    :min="0"
                    suffix="%"
                    :rules="[rules.required, v => rules.valorMaximo(v, 100)]"
                  ></v-text-field>
                  <v-text-field
                    v-else
                    :label="`${$tc('label.meta_de', 1)} *`"
                    v-model="meta.vlrMetaDe"
                    @change="acao.indAcaoAlterada = true"
                    :disabled="somenteLeitura"
                    type="Number"
                    :min="0"
                    :rules="[rules.required, rules.valorInteiro]"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="4">
                  <input-money
                    v-if="calculoValor && !unidadeQuantidade"
                    :label="$tc('label.meta_ate', 1)"
                    v-model="meta.vlrMetaAte"
                    id="meta_ate_input_money"
                    @change="acao.indAcaoAlterada = true"
                    :value="meta.vlrMetaAte"
                    :disabled="somenteLeitura"
                    :rules="[rules.metaAteMenorValor]"
                    class="v-text-field"/>
                  <v-text-field
                    v-else-if="calculoVolume && !unidadeQuantidade"
                    :label="$tc('label.meta_ate', 1)"
                    v-model="meta.vlrMetaAte"
                    id="meta_ate_text_field_volume"
                    @change="acao.indAcaoAlterada = true"
                    :disabled="somenteLeitura"
                    type="Number"
                    :min="0"
                    :suffix="sigla"
                    :rules="[rules.metaAteMenorVolume]"
                  ></v-text-field>
                  <v-text-field
                    v-else-if="calculoPercentual && !unidadeQuantidade"
                    :label="$tc('label.meta_ate', 1)"
                    v-model="meta.vlrMetaAte"
                    id="meta_ate_text_field_percentual"
                    @change="acao.indAcaoAlterada = true"
                    :disabled="somenteLeitura"
                    type="Number"
                    :min="0"
                    suffix="%"
                    :rules="[rules.metaAteMenorVolume, v => rules.valorMaximo(v, 100)]"
                  ></v-text-field>
                  <v-text-field
                    v-else
                    :label="$tc('label.meta_ate', 1)"
                    v-model="meta.vlrMetaAte"
                    id="meta_ate_text_field"
                    @change="acao.indAcaoAlterada = true"
                    :disabled="somenteLeitura"
                    type="Number"
                    :min="0"
                    :rules="[rules.metaAteMenorVariavel, rules.valorInteiro]"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="4">
                  <v-text-field
                    v-if="verbaPercentual"
                    :label="`${$t('label.verba', 1)} *`"
                    v-model="meta.vlrBonificacao"
                    id="verba_text_field"
                    @change="acao.indAcaoAlterada = true"
                    suffix="%"
                    :disabled="somenteLeitura"
                    type="Number"
                    :min="0"
                    :rules="[rules.requiredValorPositivo, v => rules.valorMaximo(v, 100)]"
                  ></v-text-field>
                  <input-money
                    v-else
                    :label="`${$t('label.verba', 1)} *`"
                    v-model="meta.vlrBonificacao"
                    id="verba_input_money"
                    @change="acao.indAcaoAlterada = true"
                    :value="meta.vlrBonificacao"
                    :disabled="somenteLeitura"
                    class="v-text-field"
                    :rules="[rules.requiredValorPositivo]"
                    :required="true"/>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn v-if="somenteLeitura" text @click.native="cancela">{{ $t('label.fechar') }}</v-btn>
            <v-btn v-if="!somenteLeitura" text @click.native="cancela">{{ $t('label.cancelar') }}</v-btn>
            <v-btn v-if="!somenteLeitura" color="primary darken-1" text @click.native="salvar">{{ $t('label.salvar') }}</v-btn>
          </v-card-actions>
        </v-card>

        <confirm
          ref="confirmRemocao"
          :message="$t('message.deseja_remover_meta')"
          :persistent="true"
          @agree="remover">
        </confirm>
      </v-form>
    </v-dialog>
  </v-card>
</template>
<script>
import InputMoney from '@/shared-components/inputs/InputMoney';
import Confirm from '@/shared-components/dialog/Confirm';
import {
  convertToMoney,
  convertToPercent,
  convertToNumberFormat,
  numberBetweenValues,
} from '@/common/functions/numeric';
import { copyObject, convertToIntegerFormat } from '@/common/functions/helpers';

export default {
  data() {
    return {
      showModal: false,
      tableKey: 1,
      rules: {
        required: (value) => (!!value || value === 0 || value === '0') || this.$t('message.campo_obrigatorio'),
        requiredValorPositivo: (value) => (!!value && value > 0) || this.$t('message.campo_obrigatorio'),
        metaAteMenorValor: () => (parseFloat(this.meta.vlrMetaAte) > parseFloat(this.meta.vlrMetaDe)
          || this.meta.vlrMetaAte === 0) || this.$t('errors.meta_ate_maior_meta_de'),
        metaAteMenorVolume: () => (!this.meta.vlrMetaAte
          || parseFloat(this.meta.vlrMetaAte) > parseFloat(this.meta.vlrMetaDe)
          || this.meta.vlrMetaAte === '0') || this.$t('errors.meta_ate_maior_meta_de'),
        metaAteMenorVariavel: () => (!this.meta.vlrMetaAte
          || parseFloat(this.meta.vlrMetaAte) > parseFloat(this.meta.vlrMetaDe)
          || this.meta.vlrMetaAte === '0') || this.$t('errors.meta_ate_maior_meta_de'),
        metaAteInfinito: () => (this.meta.vlrMetaAte === '' || this.meta.vlrMetaAte > 0) || this.$t('errors.meta_ate_maior_meta_de'),
        valorMaximo: (value, max) => value <= max || this.$t('errors.valor.maximo', { max }),
        valorInteiro: (value) => ((!!value || value === 0) && (Number.isInteger(Number(value)))) || this.$t('errors.valor.inteiro'),
      },
      edicao: false,
      meta: {
        index: -1,
        vlrMetaDe: 0.0,
        vlrMetaAte: 0.0,
        vlrBonificacao: 0.0,
      },
      headers: [
        {
          text: this.$t('label.meta_de'), value: 'meta_de', sortable: false, align: 'center',
        },
        {
          text: this.$t('label.meta_ate'), value: 'meta_ate', sortable: false, align: 'center',
        },
        {
          text: this.$t('label.verba'), value: 'vlr_bonificacao', sortable: false, align: 'center',
        },
      ],
    };
  },
  props: {
    somenteLeitura: {
      type: Boolean,
      default: false,
    },
    calculoVolume: {
      type: Boolean,
      default: false,
    },
    calculoValor: {
      type: Boolean,
      default: false,
    },
    calculoPercentual: {
      type: Boolean,
      default: false,
    },
    unidadeQuantidade: {
      type: Boolean,
      default: false,
    },
    metas: {
      type: Array,
      default: () => ([]),
    },
    sigla: {
      type: String,
      default: '',
    },
    verbaPercentual: {
      type: Boolean,
      default: false,
    },
    subtitulo: {
      type: String,
      default: '',
    },
    acao: Object,
  },
  watch: {
    metas: {
      handler() {
        this.ajustarValorAcao();
      },
      deep: true,
    },
  },
  components: {
    InputMoney,
    Confirm,
  },
  methods: {
    convertToMoney,
    convertToPercent,
    convertToNumberFormat,
    numberBetweenValues,
    convertToIntegerFormat,
    novo() {
      this.edicao = false;
      this.meta = {
        index: -1,
        vlrMetaDe: 0.0,
        vlrMetaAte: 0.0,
        vlrBonificacao: 0.0,
      };
      this.showModal = true;
    },
    cancela() {
      this.showModal = false;
      this.meta = {
        index: -1,
        vlrMetaDe: 0.0,
        vlrMetaAte: 0.0,
        vlrBonificacao: 0.0,
      };
    },
    ajustarValorAcao() {
      if (!this.verbaPercentual && this.metas && this.metas.length) {
        this.$emit('PlanejamentoAcaoFormApuracaoIntervalo__maiorValorMetaAte',
          this.pegarMaiorValorDaMeta());
      }
    },
    fecharModalMetaIntervalo() {
      this.$emit('PlanejamentoContratoFormMetaIntervalo__fecharModalMetaIntervalo');
    },
    pegarMaiorValorDaMeta() {
      return this.metas.map((m) => m.vlrBonificacao)
        .reduce((a, b) => Math.max(a, b));
    },
    salvar() {
      if (this.meta.vlrMetaDe) {
        this.meta.vlrMetaDe = Number(this.meta.vlrMetaDe);
      }
      if (this.meta.vlrMetaAte) {
        this.meta.vlrMetaAte = Number(this.meta.vlrMetaAte);
      }
      if (this.meta.vlrBonificacao) {
        this.meta.vlrBonificacao = Number(this.meta.vlrBonificacao);
      }
      if (!this.$refs.formModal.validate() || this.intervaloEmConflito()
        || this.valoresNegativos()) return;
      if (this.edicao) {
        this.metas.forEach((meta, index) => {
          if (index === this.meta.index) {
            meta.vlrMetaDe = this.meta.vlrMetaDe;
            meta.vlrMetaAte = this.meta.vlrMetaAte;
            meta.vlrBonificacao = this.meta.vlrBonificacao;
          }
        });
      } else {
        this.metas.push(this.meta);
      }
      this.ordenarLista();
      this.tableKey += 1;
      this.showModal = false;
      this.meta = {
        index: -1,
        vlrMetaDe: 0.0,
        vlrMetaAte: 0.0,
        vlrBonificacao: 0.0,
      };
    },
    ordenarLista() {
      this.metas.sort((a, b) => a.vlrMetaDe - b.vlrMetaDe);
    },
    exibirMeta(valor) {
      if (this.calculoVolume && !this.unidadeQuantidade) {
        return convertToNumberFormat(valor);
      }
      if (this.calculoPercentual && !this.unidadeQuantidade) {
        return convertToPercent(valor);
      }
      if (this.calculoValor && !this.unidadeQuantidade) {
        return convertToMoney(valor);
      }
      if (this.unidadeQuantidade) {
        return convertToIntegerFormat(valor);
      }
      return convertToNumberFormat(valor, {
        precision: 0,
      });
    },
    exibirPagamento(valor) {
      if (this.verbaPercentual) {
        return convertToPercent(valor);
      }
      return convertToMoney(valor);
    },
    editar(item) {
      this.edicao = true;
      this.meta = copyObject(item);
      this.meta.index = this.metas.indexOf(item);
      this.showModal = true;
    },
    openConfirmRemocao(item) {
      this.meta = item;
      this.$refs.confirmRemocao.open();
    },
    remover() {
      this.metas.splice(this.metas.indexOf(this.meta), 1);
      this.tableKey += 1;
    },
    intervaloEmConflito() {
      for (let i = 0; i < this.metas.length; i += 1) {
        const intervalo = this.metas[i];
        if (this.meta.index !== i
          && this.validarConflito(intervalo.vlrMetaDe, intervalo.vlrMetaAte)) {
          this.$toast(this.$t('errors.meta.intervalos.conflito'));
          return true;
        }
      }
      return false;
    },
    validarConflito(vlrMetaDe, vlrMetaAte) {
      if (!this.meta.vlrMetaAte) {
        return this.validarConflitoEntreValorInfinito(vlrMetaDe, vlrMetaAte);
      }
      return this.validarConflitoEntreValores(vlrMetaDe, vlrMetaAte);
    },
    validarConflitoEntreValores(vlrMetaDe, vlrMetaAte) {
      return this.numberBetweenValues(this.meta.vlrMetaDe, vlrMetaDe, vlrMetaAte)
          || (this.numberBetweenValues(this.meta.vlrMetaAte, vlrMetaDe, vlrMetaAte))
          || ((vlrMetaAte !== 0) && this.numberBetweenValues(vlrMetaAte, this.meta.vlrMetaDe, this.meta.vlrMetaAte))
          || this.numberBetweenValues(vlrMetaDe, this.meta.vlrMetaDe,
            this.meta.vlrMetaAte);
    },
    validarConflitoEntreValorInfinito(vlrMetaDe, vlrMetaAte) {
      return !vlrMetaAte
        || this.numberBetweenValues(this.meta.vlrMetaDe, vlrMetaDe, vlrMetaAte)
        || this.meta.vlrMetaDe < vlrMetaDe;
    },
    valoresNegativos() {
      if (this.meta.vlrMetaDe < 0 || this.meta.vlrMetaAte < 0 || this.meta.vlrBonificacao < 0) {
        this.$toast(this.$t('errors.meta.negativa'));
        return true;
      }
      return false;
    },
    incluirIntervaloZerado() {
      let valorMeta = 0;
      if (this.metas && this.metas.length) {
        valorMeta = this.unidadeQuantidade ? this.metas[this.metas.length - 1].vlrMetaAte + parseFloat(1)
          : (this.metas[this.metas.length - 1].vlrMetaAte + parseFloat(0.01)).toFixed(2);
        this.edicao = false;
        this.meta = {
          index: -1,
          vlrMetaDe: valorMeta,
          vlrMetaAte: 0.0,
          vlrBonificacao: 0.0,
        };
        this.showModal = true;
      }
      if (!this.metas.length) {
        this.novo();
      }
    },
  },
  mounted() {
    if (this.metas && this.metas.length) {
      this.ajustarValorAcao();
    }
    if (!this.somenteLeitura) {
      this.headers.push(
        {
          text: '', value: '', sortable: false, align: 'center',
        },
      );
    }
  },
};
</script>
