import { mapGetters } from 'vuex';
import { validarIntervalosIguais } from '@/common/functions/date-utils';

export default {
  data() {
    return {
      extensoesPorTipo: {},
      produtoPorTipo: {},
    };
  },
  computed: {
    ...mapGetters('metadados', [
      'getProdutoMetadado',
    ]),
  },
  methods: {
    mapearConfiguracoesPorTipo(acoes) {
      acoes.forEach(this.mapearConfiguracoesPorTipoAcao);
    },
    mapearConfiguracoesPorTipoAcao(acao) {
      if (acao.passoGeral.produto && acao.passoGeral.produto.length) {
        this.mapearProdutos(acao);
      } else {
        this.mapearExtensoes(acao);
      }
    },
    mapearProdutos(acao) {
      if (!this.produtoPorTipo[acao.idAcaoTipo]) {
        this.produtoPorTipo[acao.idAcaoTipo] = {};
      }

      this.produtoPorTipo[acao.idAcaoTipo][this.montarIdsProdutos(acao
        .passoGeral.produto)] = this.montarResumoAcao(acao);
    },
    montarIdsProdutos(produtos) {
      return produtos.map((p) => p.id)
        .sort()
        .reduce((ids, id) => ids.toString() + id.toString(), '');
    },
    mapearExtensoes(acao) {
      if (!this.extensoesPorTipo[acao.idAcaoTipo]) {
        this.extensoesPorTipo[acao.idAcaoTipo] = {};
      }
      const extensao = this.getExtensao(acao);
      if (extensao) {
        this.extensoesPorTipo[acao.idAcaoTipo][extensao.id] = this.montarResumoAcao(acao);
      }
    },
    montarResumoAcao(acao) {
      return {
        id: acao.id,
        descricao: acao.passoGeral.descricao,
        index: acao.index,
      };
    },
    atualizacaoConfiguracoesPorTipoAcao(anterior, novo) {
      this.removerConfiguracoesPorTipoAcao(anterior);
      this.mapearConfiguracoesPorTipoAcao(novo);
    },
    removerConfiguracoesPorTipoAcao(acao) {
      if (acao.passoGeral.produto && acao.passoGeral.produto.length) {
        this.removerProdutos(acao);
      } else {
        this.removerExtensoes(acao);
      }
    },
    removerProdutos(acao) {
      delete this.produtoPorTipo[acao.idAcaoTipo][this.montarIdsProdutos(acao.passoGeral.produto)];
    },
    removerExtensoes(acao) {
      const extensao = this.getExtensao(acao);
      if (extensao) {
        delete this.extensoesPorTipo[acao.idAcaoTipo][extensao.id];
      }
    },
    validarAcaoDuplicada(acao, configuracao, acoes) {
      if (configuracao.indPermiteDuplicidadeAcao) {
        return true;
      }
      if (acao.passoGeral.produto && acao.passoGeral.produto.length) {
        return this.validarProdutos(acao, configuracao)
          && this.validarPeriodoDuplicado(acao, acoes, configuracao);
      }
      return this.validarExtensoesEPeriodoDuplicado(acao, configuracao, acoes);
    },
    validarProdutos(acao) {
      const ids = this.montarIdsProdutos(acao.passoGeral.produto);
      if (!this.produtoPorTipo[acao.idAcaoTipo]
        || !this.produtoPorTipo[acao.idAcaoTipo][ids]) {
        return true;
      }

      const acaoProduto = this.produtoPorTipo[acao.idAcaoTipo][ids];

      if ((acaoProduto.id && acaoProduto.id !== acao.id)
        || (acaoProduto.index && acaoProduto.index !== acao.index)) {
        this.$toast(
          this.$t('message.configuracao_produto_ja_utilizada', { descricao: acaoProduto.descricao }),
        );
        return false;
      }
      return true;
    },
    validarExtensoesEPeriodoDuplicado(acao, configuracao, acoes = []) {
      if (!this.herdarPeriodoAcao(configuracao)) {
        return true;
      }

      const acaoDuplicada = acoes.find((acaoExistente) => {
        if ((acao.index === undefined || acao.index !== acaoExistente.index)
          && (acao.id === undefined || acao.id !== acaoExistente.id)
          && acao.idAcaoTipo === acaoExistente.idAcaoTipo
          && this.datasIguais(acao, acaoExistente)
          && !this.extensoesValidas(acao, acaoExistente)) {
          return acaoExistente;
        }
        return false;
      });
      if (acaoDuplicada) {
        this.$toast(
          this.$t('message.periodo_acao_duplicado', { descricao: acaoDuplicada.passoGeral.descricao }),
        );
        return false;
      }
      return true;
    },
    validarExtensoes(acao, acaoExistente) {
      const extensao = this.getExtensao(acao);
      const extensaoExistente = this.getExtensao(acaoExistente);
      if (!extensao && !extensaoExistente) {
        return false;
      }
      if (!extensao || !extensao.id
        || !this.extensoesPorTipo[acao.idAcaoTipo]
        || !this.extensoesPorTipo[acao.idAcaoTipo][extensao.id]) {
        return true;
      }
      const acaoExtensao = this.extensoesPorTipo[acao.idAcaoTipo][extensao.id];
      if ((acaoExtensao.id && acaoExtensao.id !== acao.id)
        || (acaoExtensao.index && acaoExtensao.index !== acao.index)) {
        this.$toast(
          this.$t('message.configuracao_produto_ja_utilizada', { descricao: acaoExtensao.descricao }),
        );
        return false;
      }
      return true;
    },
    validarPeriodoDuplicado(acao, acoes = [], configuracao) {
      if (!this.herdarPeriodoAcao(configuracao)) {
        return true;
      }
      let validacao = true;
      acoes.forEach((acaoExistente) => {
        if ((acao.index === undefined || acao.index !== acaoExistente.index)
          && (acao.id === undefined || acao.id !== acaoExistente.id)
          && acao.idAcaoTipo === acaoExistente.idAcaoTipo
          && this.datasIguais(acao, acaoExistente)
          && !this.extensoesValidas(acao, acaoExistente)) {
          this.$toast(
            this.$t('message.periodo_acao_duplicado', { descricao: acaoExistente.passoGeral.descricao }),
          );
          validacao = false;
        }
      });
      return validacao;
    },
    extensoesValidas(acao, acaoExistente) {
      const extensao = this.getExtensao(acao);
      const extensaoExistente = this.getExtensao(acaoExistente);
      if ((!extensao || !extensao.id)
        && (!extensaoExistente || !extensaoExistente.id)) {
        return false;
      }

      if (!extensao || !extensao.id
        || !this.extensoesPorTipo[acao.idAcaoTipo]
        || !this.extensoesPorTipo[acao.idAcaoTipo][extensao.id]) {
        return true;
      }
      const acaoExtensao = this.extensoesPorTipo[acao.idAcaoTipo][extensao.id];
      if ((acaoExtensao.id && acaoExtensao.id !== acao.id)
        || (acaoExtensao.index && acaoExtensao.index !== acao.index)) {
        return false;
      }
      return true;
    },
    datasIguais(acao, acaoExistente) {
      const dataInicio1 = acao.passoComportamento.dtaInicio;
      const dataFim1 = acao.passoComportamento.dtaFim;
      const dataInicio2 = acaoExistente.passoComportamento.dtaInicio;
      const dataFim2 = acaoExistente.passoComportamento.dtaFim;
      return validarIntervalosIguais(dataInicio1, dataFim1, dataInicio2, dataFim2);
    },
    herdarPeriodoAcao(configuracao) {
      const { tipoVigencia } = configuracao;
      const herdarPeriodo = ['HERDAR_PERIODO_ACAO'].includes(tipoVigencia);

      return herdarPeriodo;
    },
    getExtensao(acao) {
      const { mapaEntidades } = this.getProdutoMetadado;

      const menorNivel = Object.values(mapaEntidades)
        .filter((e) => e.indRecursiva)
        .filter((e) => acao.passoGeral[e.label])
        .sort((e1, e2) => (e1.indiceRecursao < e2.indiceRecursao ? -1 : 1))
        .map((e) => e.label)[0];
      return acao.passoGeral[menorNivel] || {};
    },
  },
};
