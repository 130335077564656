<style>
.PlanejamentoContratoFormCampos__Card--treslinhas {
  min-height: 310px;
  height: 100%;
}
.PlanejamentoContratoFormCampos .title-float {
  font-weight: 400;
  font-size: 20px;
}
</style>
<template>
<div class="PlanejamentoContratoFormCampos" v-show="carregado">
  <v-card>
    <v-card-title class="pb-0">
      <div class="card-title">
        {{ $t('label.criacao_contrato')}} :: {{ tituloTipoContrato }}
      </div>
      <v-spacer></v-spacer>
      <div class="card-title" v-if="!novoContrato">
          {{ $t('label.id_contrato')}}: {{ contrato.id }}
      </div>
      <v-spacer></v-spacer>
      <div class="card-title" v-if="carregado">
          {{ $t('label.status')}}: <span :style="`color: ${corStatus}`">{{ contrato.status }}</span>
          <v-tooltip left v-if="mostrarAcompanhamento(contrato)">
            <template v-slot:activator="{ on }">
              <v-btn text icon
                    v-on="on"
                    @click.native="abrirAcompanhamento(contrato)">
                <v-icon >assignment_turned_in</v-icon>
              </v-btn>
            </template>
            <span>{{ $t('label.acompanhamento_fluxo_aprovacao') }}</span>
          </v-tooltip>
      </div>
    </v-card-title>
    <v-subheader>
      {{subtituloTipoContrato}}
    </v-subheader>

    <v-form ref="formGeral"
      lazy-validation
      auto-complete="off">
      <v-container fluid grid-list-md pt-0 pl-3>
        <v-row>
          <v-col cols="12">
            <v-text-field
              id="descricao"
              v-if="exibirDescricao"
              v-model="contrato.descricao"
              :rules="[rules.required]"
              :disabled="somenteLeitura"
              :label="`${$tc('label.descricao', 1)} *`"/>
          </v-col>
          <v-col cols="12"
            v-if="contratoEdicao.fluxo && contratoEdicao.fluxo.passoAtual.observacaoContrato">
            <v-text-field
              v-model="contratoEdicao.fluxo.passoAtual.observacaoContrato"
              :label="`${$tc('label.justificativa', 1)}`"
              :disabled="true"/>
          </v-col>
        </v-row>
      </v-container>
    </v-form>
  </v-card>

  <v-container fluid grid-list-md>
    <v-row>
      <v-col cols="12" class="pa-0 mb-2 mt-3">
        <div class="title-float accent--text">
          {{$tc('label.configuracao', 1)}}
        </div>
      </v-col>
      <v-col cols="12" md="6" class="py-0 pl-0">
        <planejamento-contrato-form-proponente
          ref="formProponente"
          :somente-leitura="somenteLeitura"
          :configuracao="configuracao"
          :novo-contrato="novoContrato"
          :tipo-contrato="tipoContrato"
          :contrato="contrato"
          @PlanejamentoContratoForm__CampoProdutoAlterado="campoProdutoAlterado"
          @PlanejamentoContratoForm__CampoDataInicioAlterado="campoDataInicioAlterado"
          @PlanejamentoContratoForm__CampoDataFimAlterado="campoDataFimAlterado"
        />
      </v-col>
      <v-col cols="12" md="6" class="py-0 pr-0">
        <planejamento-contrato-form-origem-contrato
          ref="formOrigemContrato"
          :somente-leitura="somenteLeitura"
          :novo-contrato="novoContrato"
          :configuracao="configuracao"
          :contrato="contrato"
          @PlanejamentoContratoForm__CampoOrigemAlterado="campoOrigemAlterado"
        />
      </v-col>
    </v-row>
  </v-container>

  <div class="mt-3 mb-2 title-float accent--text">
    {{$tc('label.campos_gerais', 1)}}
  </div>
  <v-card>
    <v-container fluid grid-list-md>
      <metadados-container-layout
        v-if="parametrosAplicados"
        :metadados-entidade="metadadosParametrizado"
        :layout-class="layoutClass"
        :input-layout="inputLayout"
        :objeto="contrato"
        :campos-formulario="camposFormulario"
        :ordenacao-campos="ordenacaoFormulario"
        :explode-hierarquia="true"
        :somente-leitura="somenteLeitura"
        :exibe-loading-overlay="false"
        @MetadadosContainerLayout_valorAlterado="valorDinamicoAlterado"
        ref="container">
        <v-col slot="depois" cols="12" md="3" v-if="possuiCoeficienteCalculoHabilitado">
          <v-text-field
            id="valorBase"
            type="number"
            :min="0"
            :step="1"
            :disabled="desabilitarCoeficienteCalculo"
            :label="`${$tc('label.valor_base', 1)} *`"
            v-model="contrato.valorBase">
            <v-tooltip v-if="verificaStatusContrato && !desabilitarCoeficienteCalculo"
              slot="append" bottom >
              <template v-slot:activator="{ on }">
              <v-icon v-on="on" slot="activator" color="primary" @click="atualizarValorBase" dark>save</v-icon>
              </template>
              <span>Salvar</span>
            </v-tooltip>
          </v-text-field>
        </v-col>
        <v-col slot="depois" cols="12" class="pt-0" v-if="isStatusEncerrado">
            <span>{{ justificativaEncerramentoContrato() }}</span>
        </v-col>
      </metadados-container-layout>
    </v-container>
  </v-card>

  <div class="mt-3 mb-2 title-float accent--text">
    {{$tc('label.anexo', 2)}}
  </div>
  <v-card>
    <v-container fluid grid-list-md>
      <planejamento-contrato-form-anexo-arquivo
        ref="formAnexoArquivo"
        :novo-contrato="novoContrato"
        :idContrato="contratoEdicao.id"
        @FormAnexoArquivo__qtde="atualizaQtdeArquivosAnexados">
      </planejamento-contrato-form-anexo-arquivo>
    </v-container>
  </v-card>
  <acompanhamento
  ref="modalAcompanhamento"
  :dados-modal-acompanhamento="dadosModalAcompanhamento"
  :fluxo="statusPassos"
  :entidade="'CONTRATO'">
  </acompanhamento>
</div>
</template>

<script>
import { mapGetters } from 'vuex';
import PlanejamentoContratoFormProponente from '@/spa/planejamento-contrato/form/passo-geral/PlanejamentoContratoFormProponente';
import MetadadosCampoDinamicoData from '@/shared-components/metadados/campos-dinamicos/MetadadosCampoDinamicoData';
import MetadadosContainerLayout from '@/shared-components/metadados/MetadadosContainerLayout';
import Acompanhamento from '@/shared-components/workflow/Acompanhamento';
import PlanejamentoContratoFormCamposDefinicao from '@/spa/planejamento-contrato/form/passo-geral/PlanejamentoContratoFormCamposDefinicao';
import PlanejamentoContratoFormOrigemContrato from '@/spa/planejamento-contrato/form/passo-geral/PlanejamentoContratoFormOrigemContrato';
import PlanejamentoContratoFormAnexoArquivo from '@/spa/planejamento-contrato/form/passo-geral/PlanejamentoContratoFormAnexoArquivo';

export default {
  components: {
    MetadadosCampoDinamicoData,
    MetadadosContainerLayout,
    PlanejamentoContratoFormProponente,
    PlanejamentoContratoFormOrigemContrato,
    PlanejamentoContratoFormAnexoArquivo,
    Acompanhamento,
  },
  mixins: [
    PlanejamentoContratoFormCamposDefinicao,
  ],
  props: {
    novoContrato: Boolean,
    somenteLeitura: Boolean,
    contratoEdicao: Object,
    tipoContrato: {
      type: Object,
      required: true,
    },
    configuracao: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapGetters('roles', [
      'getAllRoles',
    ]),
    possuiRoleContratoCRUD() {
      if (!this.getAllRoles) {
        return false;
      }
      return !!this.getAllRoles
        .filter((el) => el === 'CONTRATO_CRUD').length;
    },
    desabilitarCoeficienteCalculo() {
      return !(((this.isStatusEmCadastro || this.isStatusEmAnalise || this.novoContrato)
        || (this.isStatusAprovado
        && this.editarCoeficienteCalculoStatusAprovado))
        && this.possuiRoleContratoCRUD);
    },
    editarCoeficienteCalculoStatusAprovado() {
      if (this.configuracao.configuracao.passo2
          && this.configuracao.configuracao.passo2.listaPadrao) {
        const listaCampo = this.configuracao.configuracao.passo2.listaPadrao
          .filter((c) => c.label === 'coeficiente_calculo');
        if (listaCampo.length > 0) {
          const coeficiente = listaCampo[0];
          return coeficiente.edicaoAprovado;
        }
        return false;
      }
      return false;
    },
    possuiCoeficienteCalculoHabilitado() {
      return this.configuracao.configuracao.passo3
          && this.configuracao.configuracao.passo3.coeficienteCalculo;
    },
    ...mapGetters('metadados', [
      'getContratoMetadado',
      'getProdutoMetadado',
    ]),
    ordenacaoFormulario() {
      const { relacaoCampos } = this.configuracao;
      return relacaoCampos;
    },
    tituloTipoContrato() {
      return this.tipoContrato != null ? this.tipoContrato.nome : '';
    },
    subtituloTipoContrato() {
      return this.tipoContrato != null ? this.tipoContrato.descricao : '';
    },
    isTemplateSomaValorMonetarioAcoes() {
      return this.configuracao.configuracao
          && this.configuracao.configuracao.passo2
          && this.configuracao.configuracao.passo2.template === 'SOMA_VALOR_MONETARIO_ACOES';
    },
    verificaStatusContrato() {
      return this.isStatusAprovado
          || this.isStatusEmAnalise
          || this.isStatusEmCadastro;
    },
    isStatusAprovado() {
      return this.contrato.status === 'Aprovado';
    },
    isStatusEncerrado() {
      return this.contrato.status === 'Encerrado';
    },
    isStatusEmAnalise() {
      return this.contrato.status === 'Em análise';
    },
    isStatusEmCadastro() {
      return this.contrato.status === 'Em Cadastro';
    },
    exibirDescricao() {
      return !this.configuracao.indDescricaoAutomatica || (this.configuracao.indDescricaoAutomatica
        && this.contrato.descricao != null && this.contrato.descricao.length);
    },
    corStatus() {
      let colorIndex = 4;
      switch (this.contrato.status.toUpperCase()) {
        case 'APROVADO':
          colorIndex = 0;
          break;
        case 'EM ANÁLISE':
          colorIndex = 3;
          break;
        case 'CANCELADO':
          colorIndex = 3;
          break;
        case 'AGUARDANDO APROVAÇÃO':
          colorIndex = 2;
          break;
        case 'REPROVADO':
          colorIndex = 1;
          break;
        default:
          colorIndex = 4;
      }
      return this.colorPalette[colorIndex];
    },
  },
  data() {
    return {
      workflowContratoResource: this.$api.workflowContrato(this.$resource),
      planejamentoContratoResource: this.$api.planejamentoContratoCadastro(this.$resource),
      statusPassos: {},
      dadosModalAcompanhamento: {},
      colorPalette: [
        '#1abc9c', // Verde
        '#e74c3c', // Vermelho
        '#e67e22', // Laranja
        '#f1c40f', // Amarelo
        '#000000', // Preto
      ],
    };
  },
  methods: {
    justificativaEncerramentoContrato() {
      const { contrato } = this;
      const data = contrato.dataCancelamento ? contrato.dataCancelamento : this.$t('label.nao_informado');
      const usuario = contrato.nomeUsuarioCancelamento ? contrato.nomeUsuarioCancelamento : this.$t('label.nao_informado');

      let justificativa;
      if (contrato.temContratoVigente) {
        justificativa = this.$t('message.encerramento_por_substituicao_contrato', { contratoVigente: contrato.idContratoVigente });
      } else if (contrato.justificativa !== this.$t('label.nao_informado') && contrato.justificativa !== undefined && contrato.justificativa !== null) {
        justificativa = contrato.justificativa;
      } else {
        justificativa = this.$t('label.nao_informado');
      }
      return this.$t('message.justificativa_encerramento_contrato_data', { data, usuario, justificativa });
    },
    atualizarValorBase() {
      if (!this.validarValorBase()) {
        return;
      }
      this.planejamentoContratoResource.atualizarValorBase(this.contrato)
        .then((res) => {
          if (res) {
            this.$toast(this.$t('message.atualizar_tabela'));
          }
        })
        .catch((err) => {
          this.$error(this, err);
        });
    },
    campoProdutoAlterado() {
      this.$emit('PlanejamentoContratoForm__CampoProdutoAlterado');
      if (this.$refs.formFocoContrato) {
        this.$refs.formFocoContrato.limpaFocoContrato();
      }
    },
    campoDataInicioAlterado(dataInicio = null) {
      this.campoDataAlterado(dataInicio, null);
    },
    campoDataFimAlterado(dataFim = null) {
      this.campoDataAlterado(null, dataFim);
    },
    campoDataAlterado(dataInicio = null, dataFim = null) {
      if (this.configuracao.indDescricaoAutomatica) {
        const descAno = this.buscarDescricaoAnoVigencia(dataInicio, dataFim);
        const { fornecedor, grupoFornecedor } = this.contrato;
        let descFornecedor = null;

        if (fornecedor && fornecedor.codNomeCnpj.length) {
          descFornecedor = fornecedor.codNomeCnpj;
        } else if (grupoFornecedor && grupoFornecedor.nomExtensao.length) {
          descFornecedor = grupoFornecedor.nomExtensao;
        }

        if (descFornecedor && descAno !== null) {
          this.contrato.descricao = descFornecedor.concat(' - ').concat(descAno);
        } else if (descFornecedor !== null) {
          this.contrato.descricao = descFornecedor;
        } else {
          this.contrato.descricao = descAno;
        }

        this.$forceUpdate();
      }

      this.$emit('PlanejamentoContratoForm__VigenciaContratoAlterada');
    },
    campoOrigemAlterado(nomOrigem) {
      if (this.configuracao.indDescricaoAutomatica) {
        const descAno = this.buscarDescricaoAnoVigencia();
        if (descAno !== null) {
          this.contrato.descricao = nomOrigem.concat(' - ').concat(descAno);
        } else {
          this.contrato.descricao = nomOrigem;
        }
        this.$forceUpdate();
      }
    },
    buscarDescricaoAnoVigencia(dataInicio = null, dataFim = null) {
      let dataInicioASerUtilizada = null;
      let dataFimASerUtilizada = null;
      if (dataInicio != null) {
        dataInicioASerUtilizada = dataInicio;
      } else {
        dataInicioASerUtilizada = this.contrato.dtaVigenciaInicio;
      }
      if (dataFim != null) {
        dataFimASerUtilizada = dataFim;
      } else {
        dataFimASerUtilizada = this.contrato.dtaVigenciaFim;
      }
      if (!!dataInicioASerUtilizada && dataInicioASerUtilizada.length
        && !!dataFimASerUtilizada && dataFimASerUtilizada.length) {
        const anoDataInicio = dataInicioASerUtilizada.substring(0, 4);
        const anoDataFim = dataFimASerUtilizada.substring(0, 4);
        let descAno = '';
        if (anoDataInicio === anoDataFim) {
          descAno = anoDataInicio;
        } else {
          descAno = anoDataInicio.concat(' à ').concat(anoDataFim);
        }
        return descAno;
      }
      return null;
    },
    valida() {
      return this.$refs.formGeral.validate()
        && this.validarValorBase()
        && this.$refs.formProponente.valida()
        && this.$refs.formOrigemContrato.valida();
    },
    validarValorBase() {
      if (!this.possuiCoeficienteCalculoHabilitado) {
        return true;
      }
      if (this.contrato.valorBase === '') {
        this.$toast(this.$t('errors.volume_base.obrigatorio'));
        return false;
      }
      if (this.contrato.valorBase < 0) {
        this.$toast(this.$t('errors.volume_base.negativo'));
        return false;
      }
      if (typeof this.contrato.valorBase === 'string'
        && this.contrato.valorBase.indexOf('.') > -1) {
        this.$toast(this.$t('errors.volume_base.decimal'));
        return false;
      }
      return true;
    },
    valorDinamicoAlterado(altercontrato) {
      const { mapaEntidades } = this.getProdutoMetadado;
      const dependenciasProduto = Object.keys(mapaEntidades);

      const { label } = altercontrato;
      if (label === 'produto' || dependenciasProduto.indexOf(label) >= 0) {
        this.$emit('PlanejamentoContratoForm__CampoProdutoAlterado');
      }
    },
    getObjetoPasso() {
      const objeto = { ...this.contrato };

      objeto.focoAcao = this.$refs.formOrigemContrato.getTextoOrigem();
      objeto.idContratoTipo = this.tipoContrato.id;

      return objeto;
    },
    setValorContrato(valor) {
      if (!this.isStatusAprovado) {
        this.contrato.valor = valor;
      }
    },
    getObjetoFormatado() {
      const { idConfiguracaoContrato, tipoVigencia } = this.configuracao;
      const {
        id, descricao, valorBase, prazoPagContrato,
      } = this.contrato;

      const objetoFormatado = {
        id,
        idContratoTipo: this.tipoContrato.id,
        idConfiguracaoContrato,
        descricao,
        valorBase,
        prazoPagContrato,
        dtaVigenciaInicio: this.contrato.dtaVigenciaInicio,
        dtaVigenciaFim: this.contrato.dtaVigenciaFim,
        ...this.$refs.container.getValoresCamposPadrao(),
      };

      if (tipoVigencia) {
        this.formataVigencia(objetoFormatado, tipoVigencia);
        objetoFormatado.tipoVigencia = tipoVigencia;
      }
      this.formataExtensoes(objetoFormatado);
      this.formataCamposDinamicos(objetoFormatado);
      this.formataOrigemContrato(objetoFormatado);

      this.formataProponenteContrato(objetoFormatado);

      return objetoFormatado;
    },
    formataVigencia(objetoFormatado, tipoVigencia) {
      if (tipoVigencia !== 'VIGENCIA_PERIODO') {
        objetoFormatado.dtaVigenciaInicio = this.contrato.dtaVigenciaInicio;
        objetoFormatado.dtaVigenciaFim = this.contrato.dtaVigenciaFim;
      }
    },

    formataExtensoes(objetoFormatado) {
      objetoFormatado.mapaExtensoes = {
        ...this.$refs.container.getValoresDependencias(),
      };
      this.configuracao.extensoesCarteira.forEach((e) => {
        objetoFormatado.mapaExtensoes[`id_${e.label}`] = this.contrato[e.label].id;
      });
    },
    formataCamposDinamicos(objetoFormatado) {
      const camposDinamicos = this.$refs.container.getValoresCamposDinamicos();
      if (Object.keys(camposDinamicos).length !== 0) {
        objetoFormatado.mapaCamposDinamicos = { ...camposDinamicos };
      }
    },
    formataFocoContrato(objetoFormatado) {
      const {
        cliente,
        holding,
        regional,
        extensaoCliente,
        clientePagador,
        fornecedorPagador,
      } = this.$refs.formFocoContrato.getContrato();

      if (holding && holding.id) {
        objetoFormatado.idHolding = holding.id;
      }
      if (cliente && cliente.id) {
        objetoFormatado.idCliente = cliente.id;
      }
      if (regional && regional.id) {
        objetoFormatado.idRegional = regional.id;
      }
      if (clientePagador && clientePagador.id) {
        objetoFormatado.idClientePagador = clientePagador.id;
      }
      if (extensaoCliente && extensaoCliente.id) {
        objetoFormatado.idExtensaoCliente = extensaoCliente.id;
      }
      if (fornecedorPagador && fornecedorPagador.id) {
        objetoFormatado.idFornecedorPagador = fornecedorPagador.id;
      }
    },
    formataOrigemContrato(objetoFormatado) {
      const { fornecedor, grupoFornecedor, fornecedorPagador } = this.contrato;

      if (this.$refs.formOrigemContrato.exibeOrigemGrupoFornecedor) {
        objetoFormatado.idGrupoFornecedor = grupoFornecedor.id;
      } else {
        objetoFormatado.idFornecedor = fornecedor.id;
      }
      if (this.$refs.formOrigemContrato.exibeFornecedorPagador) {
        objetoFormatado.idFornecedorPagador = fornecedorPagador.id;
      }
    },
    formataProponenteContrato(objetoFormatado) {
      const { divisao, unidadeNegocio, periodoPlanejamento } = this.contrato;
      objetoFormatado.idDivisao = divisao.id;
      objetoFormatado.idUnidadeNegocio = unidadeNegocio ? unidadeNegocio.id : null;
      objetoFormatado.idPeriodo = periodoPlanejamento ? periodoPlanejamento.id : null;
    },
    configuraMetadadoCruzados() {
      const configuracoes = [
        {
          entidade: 'produto',
          metadadosCampo: this.getProdutoMetadado,
        },
      ];
      configuracoes.forEach((cfg) => {
        const campos = this.camposFormulario.padrao;
        const campo = campos.filter((c) => c.labelCampo === cfg.entidade)[0];
        campo.metadadosCampo = cfg.metadadosCampo;
      });
    },
    aplicaParametrizacoes() {
      if (this.isTemplateSomaValorMonetarioAcoes) {
        const campoValor = this.camposFormulario.padrao.filter((c) => c.labelCampo === 'valor')[0];
        campoValor.desAtributos.desabilitado = true;
        campoValor.vlrObrigatorio = false;
      }
      this.metadadosParametrizado = this.configuracao
        .aplicaParametrizacao(this.getContratoMetadado);
      this.configuracao
        .parametrizaCamposOpcionais(this.camposFormulario.padrao);
    },
    montaObjetoContrato() {
      this.contrato = {
        ...this.contratoEdicao.passoGeral,
        ...this.contratoEdicao.passoGeral.mapaExtensoes,
        ...this.contratoEdicao.passoGeral.mapaCamposDinamicos,
      };

      const { status } = this.contrato;
      this.contrato.status = this.$tc(`status_entidade.${status.toLowerCase()}`, 1);

      this.$refs.formProponente.setContratoEdicao(this.contrato);
      this.$refs.formOrigemContrato.setContratoEdicao(this.contrato);
      setTimeout(() => {
        this.carregado = true;
      });
    },
    camposDeProdutoDoContrato() {
      if (this.ordenacaoFormulario.indexOf('produto') >= 0) {
        return [{ label: 'produto' }];
      }

      const amostragem = this.amostragemDeCamposDeProduto();
      const { mapaEntidades } = this.metadadosParametrizado;

      const campos = Object.values(mapaEntidades)
        .filter((c) => amostragem.indexOf(c.label) >= 0)
        .filter((c) => this.ordenacaoFormulario.indexOf(c.label) >= 0);

      return campos;
    },
    amostragemDeCamposDeProduto() {
      const { mapaEntidades } = this.getProdutoMetadado;
      return Object.values(mapaEntidades)
        .map((c) => c.label);
    },
    preencherValoresAutomaticos() {
      if (this.configuracao.indDescricaoAutomatica) {
        this.contrato.descricao = this.configuracao.descricaoAutomatica;
      }
    },
    atualizaQtdeArquivosAnexados(qtde = 0) {
      this.qtdeArquivosAnexados = qtde;
      this.$emit('PlanejamentoContratoForm__AtualizaIndTemEvidencia', qtde > 0);
    },
    mostrarAcompanhamento(item) {
      return item.status !== 'EM_CADASTRO' && item.status !== 'CANCELADO';
    },
    temFluxo(item) {
      return item.ind_possui_fluxo_aprovacao && item.tem_fluxo;
    },
    abrirAcompanhamento(item) {
      this.dadosModalAcompanhamento = {
        idContrato: item.id,
      };
      const idContrato = item.id;
      this.workflowContratoResource
        .existeFluxoCadastrado({ idContrato })
        .then((res) => {
          const existeFluxo = res.data;
          if (!existeFluxo) {
            const msg = this.$t('message.contrato_finalizado_sem_fluxo');
            this.$toast(msg);
            return;
          }
          this.exibeModalAcompanhamento(idContrato);
        });
    },
    exibeModalAcompanhamento(idContrato) {
      this.workflowContratoResource.statusPasso({ idContrato }, { idContrato })
        .then((res) => {
          this.statusPassos = res.data;
          if (this.statusPassos.passos) {
            const passo = this.statusPassos.passos.find((e) => e.statusPasso === 'AGUARDANDO_APROVACAO');
            if (passo) {
              passo.isProximoPasso = true;
            }
          }
          setTimeout(() => this.$refs.modalAcompanhamento.open());
        })
        .catch((err) => {
          this.$toast(this.$t(err));
        });
    },
  },
  mounted() {
    if (!this.novoContrato) {
      this.montaObjetoContrato();
    } else {
      this.iniciaObjetoContrato();
      // this.preencherValoresAutomaticos();
    }
    this.aplicaParametrizacoes();
    this.configuraMetadadoCruzados();
    this.parametrosAplicados = true;
  },
};
</script>
