<template>
  <div>
    <planejamento-acao-form-resumo
      :exibe-cliente-pagador="exibeClientePagador"
      :objeto-passo-geral="objetoPassoGeralContrato"
      :ordenacao-formulario="ordenacaoFormulario"
      :exibe-fornecedor-pagador="exibeFornecedorPagador"
      :somenteLeitura="somenteLeitura"
      ref="resumo">
      <div class="card-title" slot="titulo-acao">
        {{ $t('label.criacao_contrato')}} :: {{ tituloTipoContrato }} :: {{$tc('title.resumo', 1)}}
      </div>
    </planejamento-acao-form-resumo>

    <div class="mt-3 mb-2 title-float accent--text">
      <v-row>
        <v-col class="mt-3 mb-2" cols="9">
          <span>
            {{$tc('label.acoes', 2)}}
          </span>
        </v-col>
        <v-col cols="3">
          <v-radio-group
            :label="$tc('label.visao', 1)"
            v-model="visaoSelecionada"
            row>
            <v-radio :label="$tc('label.card', 1)" value="CARD"></v-radio>
            <v-radio :label="$t('label.lista')" value="LISTA"></v-radio>
          </v-radio-group>
        </v-col>
      </v-row>
    </div>

    <v-container fluid grid-list-md style="padding:0">
      <v-row>
        <v-col cols="12" sm="6" md="4" v-if="!somenteLeitura">
          <v-tooltip right
            v-model="exibeDica"
            content-class="PlanejamentoContratoFormAcoesControle__Dica">
            <template v-slot:activator="{ on }">
              <v-combobox
                id="tipo_acao"
                v-model="tipoAcao"
                :items="tiposAcaoContrato"
                @input="selecionadoTipoAcao"
                autocomplete="off"
                :item-disabled="'atingiuMaximo'"
                item-text="nome"
                item-value="id"
                :label="$t('label.tipo_acao', 1)"
                v-on="on">
                <template slot="item" slot-scope="{ item }">
                  <v-list-item-content v-bind:class="{ 'primary--text': item.atingiuMaximo}">
                    <span>{{ item.nome }}</span>
                  </v-list-item-content>
                  <v-list-item-action>
                    <v-icon color="primary"
                      v-if="item.atingiuMaximo">
                      playlist_add_check
                    </v-icon>
                  </v-list-item-action>
                </template>
              </v-combobox>
            </template>
            <span>
              {{$t('message.selecione_tipo_acao_para_contrato')}}
            </span>
          </v-tooltip>
        </v-col>
        <v-spacer v-show="!somenteLeitura" />
        <v-col cols="12" sm="6" md="3" v-show="acoes.length > 0">
          <v-text-field
            v-model="filtro"
            @input="filtrarAcoes"
            append-icon="search"
            :label="$t('label.filtrar')"
            single-line
            clearable
            hide-details>
          </v-text-field>
        </v-col>
      </v-row>
    </v-container>
    <v-container fluid grid-list-md style="padding:0">
      <planejamento-contrato-form-acoes-controle-card
        v-show="isVisaoCard"
        ref="containerAcoes"
        :acoes="acoes"
        :acoes-formatadas="acoesFormatadas"
        :somente-leitura="somenteLeitura"
        :configuracao="configuracao"
        :contrato="objetoPassoGeralContrato"
        :contrato-edicao="contratoEdicao"
        :tipos-acao-contrato="tiposAcaoContrato"
        :tipos-acao-solicitaveis="tiposAcaoSolicitaveis"
        :pode-solicitar-aprovacao-contrato="podeSolicitarAprovacaoContrato"
        :tem-fluxo-contrato="temFluxoContrato"
        @PlanejamentoContratoFormAcoesControle__RemoveAcao="registraRemocaoAcao"
        @PlanejamentoContratoFormAcoesControle__EditaAcao="editaAcao"
        @PlanejamentoContratoFormAcoesControle__CopiaAcao="copiaAcao"
        @PlanejamentoContratoFormAcoesControlesItens__pushAcaoRemovida="novaAcaoRemovida"
        @PlanejamentoContratoFormAcoesControle__CancelarAcao="cancelarAcao"
        @PlanejamentoContratoFormAcoesControle__CancelarFluxo="atualizaEmCadastro"
        @PlanejamentoContratoFormAcoesControle__ExecutarAnalise="executarAnalise"
        @PlanejamentoContratoFormAcoesControle__atualizaDadosFluxoAcao="atualizaDadosFluxoAcao"
        />
      <planejamento-contrato-form-acoes-controle-lista
        v-show="isVisaoLista"
        ref="containerAcoesList"
        :acoes="acoes"
        :acoes-formatadas="acoesFormatadas"
        :somente-leitura="somenteLeitura"
        :configuracao="configuracao"
        :contrato="objetoPassoGeralContrato"
        :contrato-edicao="contratoEdicao"
        :tipos-acao-contrato="tiposAcaoContrato"
        :tipos-acao-solicitaveis="tiposAcaoSolicitaveis"
        :pode-solicitar-aprovacao-contrato="podeSolicitarAprovacaoContrato"
        :tem-fluxo-contrato="temFluxoContrato"
        @PlanejamentoContratoFormAcoesControle__RemoveAcao="registraRemocaoAcao"
        @PlanejamentoContratoFormAcoesControle__EditaAcao="editaAcao"
        @PlanejamentoContratoFormAcoesControle__CopiaAcao="copiaAcao"
        @PlanejamentoContratoFormAcoesControlesItens__pushAcaoRemovida="novaAcaoRemovida"
        @PlanejamentoContratoFormAcoesControle__CancelarAcao="cancelarAcao"
        @PlanejamentoContratoFormAcoesControle__CancelarFluxo="atualizaEmCadastro"
        @PlanejamentoContratoFormAcoesControle__ExecutarAnalise="executarAnalise"
        @PlanejamentoContratoFormAcoesControle__atualizaDadosFluxoAcao="atualizaDadosFluxoAcao"
      />
    </v-container>

    <v-btn
      v-if="canEdit && !temFluxoContrato"
      v-model="fab"
      color="primary"
      fab
      @click="adicionarNovaAcao"
      id="contrato_adicionar_acao"
      class="v-btn__fab PlanejamentoContratoFormAcoesControle__adicionar_contrato_btn"
      bottom right fixed
      >
      <v-icon id="add">add</v-icon>
    </v-btn>

    <v-dialog
      v-if="canEdit"
      v-model="exibeModalNovaAcao"
      :max-width="larguraModal">
      <planejamento-contrato-modal-nova-acao
        @PlanejamentoAcaoModalNovaAcao__CadastrarModalNovaAcao="cadastrarNovaAcao"
        @PlanejamentoAcaoModalNovaAcao__FecharModalNovaAcao="fecharModalNovaAcao"
        @PlanejamentoAcaoModalNovaAcao__SetLarguraModal="setLarguraModal"
        :tiposAcaoContrato="tiposAcaoContrato"
        ref="modalNovaAcao">
      </planejamento-contrato-modal-nova-acao>
    </v-dialog>
  </div>
</template>
<script>
import ControleAcoesDuplicadas from '@/spa/planejamento-contrato/form/passo-acoes/ControleAcoesDuplicadas';
import PlanejamentoAcaoFormResumo from '@/spa/planejamento-acao/form/PlanejamentoAcaoFormResumo';
import PlanejamentoContratoFormAcoesControleLista from '@/spa/planejamento-contrato/form/passo-acoes/PlanejamentoContratoFormAcoesControleLista';
import PlanejamentoContratoFormAcoesControleCard from '@/spa/planejamento-contrato/form/passo-acoes/PlanejamentoContratoFormAcoesControleCard';
import PlanejamentoContratoModalNovaAcao from '@/spa/planejamento-contrato/form/passo-acoes/PlanejamentoContratoModalNovaAcao';
import { generateComputed } from '@/common/functions/roles-computed-generator';

export default {
  props: {
    somenteLeitura: Boolean,
    contratoEdicao: Object,
    novoContrato: Boolean,
    podeSolicitarAprovacaoContrato: Boolean,
    temFluxoContrato: Boolean,
    configuracao: {
      type: Object,
      required: true,
    },
    objetoPassoGeralContrato: {
      type: Object,
      required: true,
    },
    alterarFinalizado: Boolean,
    copia: Boolean,
    edicao: Boolean,
    novo: Boolean,
    getObjetoFormatado: Function,
  },
  components: {
    PlanejamentoAcaoFormResumo,
    PlanejamentoContratoFormAcoesControleCard,
    PlanejamentoContratoFormAcoesControleLista,
    PlanejamentoContratoModalNovaAcao,
  },
  mixins: [ControleAcoesDuplicadas],
  computed: {
    ...generateComputed('CONTRATO', [
      'canEdit',
    ]),
    isVisaoCard() {
      return this.visaoSelecionada === 'CARD';
    },
    isVisaoLista() {
      return this.visaoSelecionada === 'LISTA';
    },
    exibeClientePagador() {
      return this.configuracao.habilitaClientePagador;
    },
    exibeFornecedorPagador() {
      return this.configuracao.habilitaFornecedorPagador;
    },
    ordenacaoFormulario() {
      const { relacaoCampos } = this.configuracao;
      return relacaoCampos;
    },
    tituloTipoContrato() {
      return this.configuracao.nome;
    },
    idContrato() {
      const { id } = this.contratoEdicao;
      return id || null;
    },
    contratoAprovado() {
      return this.idContrato && this.contratoEdicao && this.contratoEdicao.passoGeral
        && this.contratoEdicao.passoGeral.status
        && this.contratoEdicao.passoGeral.status === 'APROVADO';
    },
    contratoEmCadastro() {
      return !this.idContrato && this.contratoEdicao && this.contratoEdicao.passoGeral
        && this.contratoEdicao.passoGeral.status
        && this.contratoEdicao.passoGeral.status === 'EM_CADASTRO';
    },
    isTemplateSomaValorMonetarioAcoes() {
      return this.configuracao.configuracao
        && this.configuracao.configuracao.passo2
        && this.configuracao.configuracao.passo2.template === 'SOMA_VALOR_MONETARIO_ACOES';
    },
  },
  data() {
    return {
      tipoAcaoResource: this.$api.tipoAcao(this.$resource),
      planejamentoContratoResource: this.$api.planejamentoContratoCadastro(this.$resource),
      workflowAcaoResource: this.$api.workflowAcao(this.$resource),

      tipoAcao: null,
      tiposAcaoContrato: [],
      tiposAcaoSolicitaveis: [],
      controleAcaoPorTipo: {},

      acoes: [],
      acoesFormatadas: [],

      extensoesPorTipo: {},
      produtoPorTipo: {},

      exibeDica: false,
      acoesRemovidas: [],

      filtro: null,
      visaoSelecionada: 'CARD',
      esperar: false,

      fab: false,
      exibeModalNovaAcao: false,
      larguraModal: 350,
    };
  },
  methods: {
    cadastrarNovaAcao(acao) {
      this.fecharModalNovaAcao();
      setTimeout(() => this.selecionadoTipoAcao(acao), 500);
    },
    fecharModalNovaAcao() {
      this.exibeModalNovaAcao = false;
    },
    setLarguraModal(largura) {
      this.larguraModal = largura;
    },
    adicionarNovaAcao() {
      this.exibeModalNovaAcao = true;
    },
    filtrarAcoes() {
      if (this.esperar) return;
      this.esperar = true;
      setTimeout(() => {
        if (this.$refs.containerAcoes) {
          this.$refs.containerAcoes.filtrar(this.filtro);
        }
        if (this.$refs.containerAcoesList) {
          this.$refs.containerAcoesList.filtrar(this.filtro);
        }
        this.esperar = false;
      }, 1000);
    },
    selecionadoTipoAcao(tipo) {
      if (!tipo) return;

      this.criaNovaAcao(tipo)
        .then((retorno) => {
          setTimeout(() => {
            if (this.$refs.containerAcoes) {
              this.$refs.containerAcoes.criaCardAcao(retorno);
            }
            if (this.$refs.containerAcoesList) {
              this.refreshListAcoes();
            }
          }, 500);
          this.registraNovaAcaoDoTipo(tipo);
          return retorno;
        }).then((retorno) => {
          this.mapearConfiguracoesPorTipoAcao(retorno.acao);
        }).catch((err) => {
          this.$error(this, err);
        });

      setTimeout(() => {
        this.tipoAcao = null;
      });
    },
    criaNovaAcao(tipoAcao) {
      const acao = {
        idAcaoTipo: tipoAcao.id,
        nomeTipoAcao: tipoAcao.nome,
      };

      return new Promise((resolve) => {
        this.$emit(
          'PlanejamentoContratoFormAcoesControle__NovaAcao',
          {
            acao,
            callback: (retorno) => this.gravarAcao(acao, resolve, retorno),
            callbackValidation: (acaoNova) => this.validarAcaoDuplicada(acaoNova,
              this.configuracao, this.acoes),
          },
        );
      });
    },
    gravarAcao(acao, resolve, retorno) {
      if (this.novoContrato) {
        return resolve(retorno);
      }
      const { acaoFormatada } = retorno;
      const { divisao, unidadeNegocio, mapaExtensoes } = this.contratoEdicao.passoGeral;
      acaoFormatada.passoGeral.idUnidadeNegocio = unidadeNegocio.id;
      acaoFormatada.passoGeral.idDivisao = divisao.id;
      acaoFormatada.passoGeral.idContrato = this.idContrato;

      this.configuracao.extensoesCarteira.forEach((e) => {
        acaoFormatada.passoGeral.mapaExtensoes[`id_${e.label}`] = mapaExtensoes[e.label].id;
      });
      this.formataFocoContrato(acaoFormatada.passoGeral);

      const param = { idContrato: this.idContrato };
      return this.planejamentoContratoResource.salvarAcao(param, { ...acaoFormatada })
        .then((resp) => {
          retorno.acao.id = resp.body;
          this.$emit('PlanejamentoContratoFormAcoesControle__carregarContrato', this.idContrato);
          return resolve(retorno);
        });
    },
    formataFocoContrato(objetoFormatado) {
      const {
        cliente,
        holding,
        regional,
        extensaoCliente,
        clientePagador,
        fornecedorPagador,
      } = this.contratoEdicao.passoGeral;

      if (holding && holding.id) {
        objetoFormatado.idHolding = holding.id;
      }
      if (cliente && cliente.id) {
        objetoFormatado.idCliente = cliente.id;
      }
      if (regional && regional.id) {
        objetoFormatado.idRegional = regional.id;
      }
      if (clientePagador && clientePagador.id) {
        objetoFormatado.idClientePagador = clientePagador.id;
      }
      if (extensaoCliente && extensaoCliente.id) {
        objetoFormatado.idExtensaoCliente = extensaoCliente.id;
      }
      if (fornecedorPagador && fornecedorPagador.id) {
        objetoFormatado.idFornecedorPagador = fornecedorPagador.id;
      }
    },
    registraRemocaoAcao(acao) {
      this.removerConfiguracoesPorTipoAcao(acao);
      this.controleAcaoPorTipo[acao.idAcaoTipo].qtdeAtual -= 1;
      this.recalcularQtdeMaxTipoAcao(acao);
      this.refreshListAcoes();
    },
    editaAcao({ acao, index, callback }) {
      this.$emit(
        'PlanejamentoContratoFormAcoesControle__AbreForm',
        {
          acao,
          callback: (retorno) => this.editaDadosAcao(retorno, acao, index, callback),
          callbackValidation: (acaoAlterada) => this.validarAcaoDuplicada(acaoAlterada,
            this.configuracao, this.acoes),
        },
      );
    },
    callbackEditaAcao(retorno, acao, index, callback) {
      this.atualizacaoConfiguracoesPorTipoAcao(acao, retorno.acao);
      callback(retorno, index);
      this.refreshListAcoes();
    },
    editaDadosAcao(retorno, acao, index, callback) {
      if (!acao.id) {
        this.callbackEditaAcao(retorno, acao, index, callback);
        return;
      }
      const { acaoFormatada } = retorno;
      const { divisao, unidadeNegocio, mapaExtensoes } = this.contratoEdicao.passoGeral;
      acaoFormatada.passoGeral.idUnidadeNegocio = unidadeNegocio.id;
      acaoFormatada.passoGeral.idDivisao = divisao.id;
      acaoFormatada.passoGeral.idContrato = this.idContrato;
      this.configuracao.extensoesCarteira.forEach((e) => {
        acaoFormatada.passoGeral.mapaExtensoes[`id_${e.label}`] = mapaExtensoes[e.label].id;
      });

      this.formataArvoreProduto(retorno, acaoFormatada);
      this.formataFocoContrato(acaoFormatada.passoGeral);

      const param = { idContrato: this.idContrato };
      this.planejamentoContratoResource.atualizarAcao(param, { ...acaoFormatada })
        .then(() => {
          this.$emit('PlanejamentoContratoFormAcoesControle__carregarContrato', this.idContrato);
          this.callbackEditaAcao(retorno, acao, index, callback);
        })
        .catch((err) => {
          this.$error(this, err);
        });
    },
    formataArvoreProduto(retorno, acaoFormatada) {
      let arvoreProduto = '';
      Object.keys(acaoFormatada.passoGeral.mapaExtensoes).forEach((e) => {
        const labelExtensao = e.substring(3);
        arvoreProduto = arvoreProduto.concat(retorno.acao.passoGeral[labelExtensao].nomExtensao).concat(', ');
      });
      retorno.acao.passoGeral.arvoreProduto = arvoreProduto.substring(0, arvoreProduto.length - 2);
    },
    copiaAcao({ acao, index, callback }) {
      this.$emit(
        'PlanejamentoContratoFormAcoesControle__AbreForm',
        {
          acao,
          callback: (retorno) => this.salvaCopiaAcao(retorno, acao, index, callback),
        },
      );
    },
    callbackCopiaAcao(retorno, acao, index, callback) {
      callback(retorno, index);
      const tipo = this.tiposAcaoContrato
        .filter((t) => t.id === acao.idAcaoTipo)[0];
      this.registraNovaAcaoDoTipo(tipo);
      this.refreshListAcoes();
    },
    salvaCopiaAcao(retorno, acao, index, callback) {
      if (this.novoContrato) {
        this.callbackCopiaAcao(retorno, acao, index, callback);
        return;
      }
      const { acaoFormatada } = retorno;
      const { divisao, unidadeNegocio, mapaExtensoes } = this.contratoEdicao.passoGeral;
      acaoFormatada.passoGeral.idUnidadeNegocio = unidadeNegocio.id;
      acaoFormatada.passoGeral.idDivisao = divisao.id;
      acaoFormatada.passoGeral.idContrato = this.idContrato;
      this.configuracao.extensoesCarteira.forEach((e) => {
        acaoFormatada.passoGeral.mapaExtensoes[`id_${e.label}`] = mapaExtensoes[e.label].id;
      });

      const param = { idContrato: this.idContrato };
      this.planejamentoContratoResource.salvarAcao(param, { ...acaoFormatada })
        .then((resp) => {
          retorno.acao.id = resp.body;
          this.callbackCopiaAcao(retorno, acao, index, callback);
        });
    },
    carregaTiposAcao() {
      const { tiposAcao } = this.configuracao;

      const promises = [];
      tiposAcao.map((tipo) => tipo.idAcaoTipo).forEach((id) => {
        const p = this.tipoAcaoResource.buscarTipoAcao({ id })
          .then((res) => {
            this.tiposAcaoContrato.push({
              ...res.data,
              atingiuMaximo: false,
            });
          });

        promises.push(p);
      });

      return Promise.all(promises);
    },
    refreshListAcoes() {
      if (this.isTemplateSomaValorMonetarioAcoes && !this.contratoAprovado) {
        if (this.qtdeAcoes() === 0) {
          this.objetoPassoGeralContrato.valor = 0;
        } else if (this.qtdeAcoes() === 1) {
          this.objetoPassoGeralContrato.valor = this.acoes[0].passoGeral.valor;
        } else {
          const valores = this.acoes.filter((a) => a.passoGeral.status !== 'CANCELADO')
            .map((a) => a.passoGeral.valor);
          this.objetoPassoGeralContrato.valor = valores.reduce((a, b) => a + b, 0);
        }
        this.$emit('PlanejamentoContratoFormAcoesControle__setValorContrato',
          this.objetoPassoGeralContrato.valor);
      }
      if (this.$refs.containerAcoesList) {
        setTimeout(this.$refs.containerAcoesList.setAcoes, 500);
      }
      if (this.$refs.containerAcoes) {
        setTimeout(() => this.$refs.containerAcoes.forcaAtualizacao(), 500);
      }
    },
    registraNovaAcaoDoTipo(tipoAcao) {
      const { id } = tipoAcao;
      const controle = this.controleAcaoPorTipo[id];
      controle.qtdeAtual += 1;

      this.verificaSeTipoDeveTravar(tipoAcao);
    },
    verificaSeTipoDeveTravar(tipoAcao) {
      const { id } = tipoAcao;
      const { qtdeMax, qtdeAtual } = this.controleAcaoPorTipo[id];

      if (qtdeMax <= qtdeAtual) {
        tipoAcao.atingiuMaximo = true;
      } else {
        tipoAcao.atingiuMaximo = false;
      }
    },
    recalcularQtdeMaxTipoAcao(acao) {
      const tipoAcao = this.tiposAcaoContrato.filter((t) => t.id === acao.idAcaoTipo)[0];
      this.verificaSeTipoDeveTravar(tipoAcao);
    },
    montaControleDeAcaoPorTipo() {
      const { tiposAcao } = this.configuracao;
      tiposAcao.forEach((tipo) => {
        this.controleAcaoPorTipo[tipo.idAcaoTipo] = {
          qtdeMax: tipo.qtdMaxAcoes,
          qtdeAtual: 0,
        };
      });
    },
    acoesParaEnvio() {
      return {
        acoes: this.acoesFormatadas.filter(Boolean),
        acoesRemovidas: this.acoesRemovidas,
      };
    },
    novaAcaoRemovida(acao) {
      if (this.novoContrato) {
        this.acoesRemovidas.push(acao.id);
      } else {
        const param = {
          idContrato: this.idContrato,
          idAcao: acao.id,
        };
        this.planejamentoContratoResource.deletarAcao(param, param)
          .then(() => {});
      }
    },
    qtdeAcoes() {
      return this.acoes.length;
    },
    carregaAcoesContrato() {
      const { passoAcoes } = this.contratoEdicao;
      const { acoes } = passoAcoes;

      this.acoes = acoes.map((objAcao) => {
        const {
          id,
          idAcaoTipo,
          nomeTipoAcao,
          passoGeral,
          passoComportamento,
        } = objAcao;
        const objRetorno = {
          id,
          idAcaoTipo,
          nomeTipoAcao,
          passoGeral: {
            ...passoGeral,
            ...passoGeral.mapaExtensoes,
            ...passoGeral.mapaCamposDinamicos,
          },
          passoComportamento: null,
        };
        if (passoComportamento) {
          objRetorno.passoComportamento = {
            ...passoComportamento,
            ...passoComportamento.mapaExcecoes,
            ...passoComportamento.metas,
          };
        }
        return objRetorno;
      });

      this.registraAcoesContrato();
      this.mapearConfiguracoesPorTipo(this.acoes);
    },
    registraAcoesContrato() {
      if (this.tiposAcaoContrato !== null && this.tiposAcaoContrato.length) {
        this.acoes
          .filter((acao) => acao.passoGeral.status !== 'CANCELADO'
            && !acao.passoGeral.indAcaoAgrupadora)
          .map((acao) => acao.idAcaoTipo)
          .map((idAcaoTipo) => this.tiposAcaoContrato
            .filter((t) => t.id === idAcaoTipo)[0])
          .forEach((tipoAcao) => this.registraNovaAcaoDoTipo(tipoAcao));
      }
    },
    cancelarAcao(dadosAcao) {
      this.planejamentoContratoResource.cancelarAcao(dadosAcao, dadosAcao)
        .then(() => {
          this.atualizaCancelamento(dadosAcao);
          this.$toast(this.$t('message.acao_cancelada'));
        })
        .catch((err) => {
          this.$toast(err.data.error);
        });
    },
    executarAnalise(dadosAcao) {
      const param = {
        idContrato: this.idContrato,
        idAcao: dadosAcao.idAcao,
      };
      this.planejamentoContratoResource.executarAnaliseAcao(param, param)
        .then(() => {
          this.atualizaEmCadastro(dadosAcao);
          this.$toast(this.$t('message.acao_cancelada'));
        })
        .catch((err) => {
          this.$toast(err.data.error);
        });
    },
    atualizaEmCadastro(dadosAcao) {
      const acao = this.acoes[dadosAcao.index];
      acao.passoGeral.status = 'EM_CADASTRO';
      this.acoes[dadosAcao.index] = acao;
      this.filtrarAcoes();
      this.acoesCarregadas();
    },
    atualizaCancelamento(dadosAcao) {
      const acao = this.acoes[dadosAcao.index];
      acao.passoGeral.justificativaCancelamento = dadosAcao.justificativa;
      acao.passoGeral.status = 'CANCELADO';
      acao.passoGeral.usuarioCancelamento = dadosAcao.usuarioCancelamento;
      this.acoes[dadosAcao.index] = acao;
      this.filtrarAcoes();
      this.acoesCarregadas();
      this.registraRemocaoAcao(acao);
    },
    atualizaDadosFluxoAcao(dadosAtualizados) {
      const acao = this.acoes[dadosAtualizados.index];
      acao.passoGeral.status = dadosAtualizados.statusAcao;
      acao.modalLista = false;
      if (dadosAtualizados.statusAcao === 'AGUARDANDO_APROVACAO') {
        acao.passoGeral.temFluxo = true;
      }
      this.acoes[dadosAtualizados.index] = acao;
      this.filtrarAcoes();
      this.acoesCarregadas();

      this.$emit('PlanejamentoContratoFormAcoesControle__atualizarStatusContrato',
        dadosAtualizados.statusContrato);
    },
    acoesCarregadas() {
      if (this.$refs.containerAcoes) {
        setTimeout(() => this.$refs.containerAcoes.forcaAtualizacao(), 500);
      }
      if (this.$refs.containerAcoesList) {
        this.$refs.containerAcoesList.setAcoes();
      }
    },
    buscarFluxosUsuarioPodeSolicitar() {
      this.workflowAcaoResource.fluxosUsuarioSolicitante()
        .then((res) => {
          this.tiposAcaoSolicitaveis = res.data;
        });
    },
    setarExibeDica(valor) {
      this.exibeDica = valor;
    },
  },
  mounted() {
    setTimeout(() => {
      this.$refs.resumo.montaResumo();
    });
    this.buscarFluxosUsuarioPodeSolicitar();
    this.montaControleDeAcaoPorTipo();
    this.carregaTiposAcao().then(() => {
      if (!this.novoContrato) {
        this.carregaAcoesContrato();
      }
      function compare(a, b) {
        if (a.nome < b.nome) {
          return -1;
        }
        if (a.nome > b.nome) {
          return 1;
        }
        return 0;
      }
      this.tiposAcaoContrato.sort(compare);
    });

    this.exibeDica = this.novoContrato;
  },
};
</script>
<style lang="scss">
.PlanejamentoContratoFormAcoesControle__Dica {
  padding: 10px;
  span {
    font-size: 14px;
  }
}
.PlanejamentoContratoFormAcoesControle__adicionar_contrato_btn {
  bottom: 62px !important;
}
</style>
